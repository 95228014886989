// COLORS
$color-sr-dark-blue: #00003c;
$color-darkest-grey: rgb(63, 63, 65);
$color-white: #fff;
$color-grey-dark: #404040;
$color-black: #222;
$color-red-sr: #ff0000;
$color-grey-light-2: #f3f3f3;
$color-grey-light-3: #f2f2f2;
$color-grey-light-border: #dedede;
$color-grey-disabled: #dfdfdf;
$alert-color-success: #149436;
$color-blue-link: #0052cc;
$color-blue: #2b5abc;
$color-red-light: #f4e7ee;
$color-red-dark: #f4ccd5;
$color-red-darkest: #c1022c;
$color-affirmative-green: rgb(19, 140, 90);
$color-gray: #8f8f90;
$color-color-btn-disabled: #dfdfdf;
$heading-color-secondary: #000000;
$color-orange: #f49b12;
// GRID
$grid-width: 120rem;
$gutter-vertical: 3.2rem; // space between columns vertical
$gutter-horizontal: 3.2rem; // space between columns horizontal
$gutter-vertical-small: 1.6rem;

// FONT SIZE
$font-size-14: 1.4rem;
$font-size-16: 1.6rem;
$font-size-20: 2rem;

// FONT WEIGHT
$font-weight-regular: 400;
$font-weight-bold: 600;

// SIZES
$toolbar-height: 40px;
$toolbar-height-mobile: 48px;
$margin-8: 0.8rem;
$margin-10: 1rem;
$margin-12: 1.2rem;
$margin-18: 1.8rem;
$margin-20: 2rem;
$margin-28: 2.8rem;
$margin-42: 4.2rem;
$margin-32: 3.2rem;
$margin-60: 6rem;
$margin-80: 8rem;
$default-box-height: 4rem;
