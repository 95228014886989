.apn-wrapper {
  @include clearfix;
  border-radius: 2px;
  display: flex;
  font-size: 1.4rem;
  border: 1px solid #b3b3b3;
  &--text {
    float: left;
    width: 70%;
    padding: 0.8rem 0 0.8rem 0.8rem;
  }
  &--button {
    float: right;
    width: 30%;
    text-align: right;
  }
  &.disabled {
    color: rgba(64, 64, 64, 0.5);
    background-color: #f3f3f3;
    border: none;
  }
}
