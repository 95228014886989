.modifiers-form {
  &-3 {
    &__wrapper {
      display: table;
      width: 100%;
      margin-top: 1rem;
      @include clearfix;
      .box {
        &--left,
        &--right {
          float: left;
          width: 50%;
          display: table-cell;
          margin-top: 0.8rem;
        }
        &--left {
          height: 4rem;
          line-height: 4rem;
          p {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
