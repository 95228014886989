.mdp-admin-add-user {
  input[type='text'] {
    min-width: 445px;
  }

  &-btn-add {
    float: right;
  }

  &-btn-close {
    float: right;
    font-weight: 100;
    right: 10px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &-title {
    height: 40px;
    line-height: 40px;
    width: 100%;
    background: #00013c;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    color: rgba(255, 255, 255, 0.87);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    user-select: none;
  }

  .srm-disabled {
    .DayPickerInput input {
      cursor: default;
    }
  }
}
