.mobile-nav {
  margin-left: auto;
  margin-right: 16px;
  display: none;
  color: $color-white;
  position: relative;
  @include respond(tab-port) {
    display: block;
  }
  .main {
    &__checkbox,
    &__menu {
      display: none;
    }
    &__button {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 100%;
      padding-top: 8px;
    }
    &__icon:before {
      font-size: 32px;
      font-family: Sportradar16-3-3, sans-serif;
      content: '\e9da';
    }
    &__menu {
      position: absolute;
      top: 48px;
      right: -16px;
      width: 360px;
      background-color: $color-grey-light-2;
      max-height: 90vh;
      overflow-y: scroll;
    }
    // FUNCTIONALITY
    &__checkbox:checked + .main__button .main__icon::before {
      content: '\e902';
    }
    &__checkbox:checked ~ .main__menu {
      display: block;
    }
  }
  // Menu
  .menu {
    &__list {
      color: $color-black;
      li {
        letter-spacing: 0.5px;
      }
    }
    &__sublist {
      background-color: $color-white;
      display: none;
      li {
        list-style-type: none;
      }
    }
  }

  .list__button,
  .list__link {
    border-bottom: 1px solid rgba($color-black, 0.15);
  }
  .list__checkbox {
    display: none;
  }
  .sublist__checkbox {
    display: none;
  }
}

.section__menu,
.section__submenu {
  display: none;
}
.section__menu--checkbox,
.section__submmenu--checkbox {
  display: none;
}
.section__link {
  cursor: pointer;
  font-size: 13px;
  text-transform: capitalize;
  color: $color-grey-dark;
  padding: 16px 16px 16px 84px;
}
// end of section
// buttons
.section__menu--button {
  cursor: pointer;
  flex: 1;
  font-size: 13px;
  padding: 16px 16px 16px 50px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  i {
    font-size: 16px;
    margin-right: 16px;
  }
}
.section__submmenu--button {
  cursor: pointer;
  flex: 1;
  font-size: 13px;
  padding: 16px 16px 16px 84px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.list__button,
.list__link,
.sublist__button {
  cursor: pointer;
  flex: 1;
  font-size: 13px;
  padding: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  i {
    font-size: 16px;
    margin-right: 16px;
  }
}
.sublist__button {
  border-bottom: 1px solid rgba($color-black, 0.15);
}
.list__button::after,
.sublist__button::after,
.section__menu--button::after,
.section__submmenu--button::after {
  font-family: Sportradar16-3-3, sans-serif;
  content: '\e900';
  margin-left: auto;
}

//  end of buttons

// checkboxes
.list__checkbox:checked + .list__button::after,
.sublist__checkbox:checked + .sublist__button::after,
.section__menu--checkbox:checked + .section__menu--button::after,
.section__submmenu--checkbox:checked + .section__submmenu--button::after {
  content: '\e901';
}

.sublist__checkbox:checked ~ .menu__sublist,
.section__menu--checkbox:checked ~ .section__menu,
.section__submmenu--checkbox:checked ~ .section__submenu {
  display: block;
}
