.mdp-docs {
  &__mobile,
  &__desktop {
    font-size: 13px;
    i {
      font-size: 16px;
      margin-right: 12px;
    }
    li {
      list-style-type: none;
    }
  }
  &__mobile {
    background-color: $color-white;
  }
  &__desktop {
    background-color: $color-grey-light-2;
  }
}
.section {
  &__list {
    background-color: $color-white;
    &--mobile,
    &--desktop {
      display: none;
    }
    &--mobile {
      padding-left: 28px;
    }
  }
  &__button {
    height: 48px;
    display: flex;
    cursor: pointer;
    align-items: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    &--desktop {
      padding-left: 22px;
      padding-right: 18px;
    }
    &:after {
      font-family: Sportradar16-3-3, sans-serif;
      content: '\e900';
      margin-left: auto;
    }
  }
  &__checkbox {
    &--mobile,
    &--desktop {
      display: none;
    }
  }
  &__list--desktop,
  &__button--mobile {
    padding-right: 18px;
    padding-left: 50px;
  }

  // funcitonality
  &__checkbox--mobile:checked + &__button,
  &__checkbox--desktop:checked + &__button {
    font-weight: bold;
    &:after {
      content: '\e901';
    }
  }

  &__checkbox--mobile:checked ~ &__list--mobile,
  &__checkbox--desktop:checked ~ &__list--desktop {
    display: block;
  }
}
.item {
  &__link {
    &.active,
    &:hover {
      font-weight: bold;
      text-decoration: underline;
    }
  }
  &__link,
  &__button {
    height: 48px;
    display: flex;
    cursor: pointer;
    align-items: center;
    letter-spacing: 0.5px;
  }
  &__list,
  &__button {
    padding-right: 18px;
    padding-left: 50px;
  }
  &__button {
    &:after {
      font-family: Sportradar16-3-3, sans-serif;
      content: '\e900';
      margin-left: auto;
    }
  }

  &__checkbox--mobile:checked + &__button {
    font-weight: bold;
    &:after {
      content: '\e901';
    }
  }

  &__checkbox--mobile,
  &__list--mobile {
    display: none;
  }
  &__checkbox--mobile:checked ~ &__list--mobile {
    display: block;
  }
}
