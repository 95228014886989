.mdp-nav {
  display: flex;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgb(222, 222, 222);
}

.mdp-nav-wrap {
  display: flex;
  width: 100%;
}

.mdp-nav-item {
  cursor: pointer;
  padding: 0 16px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #000;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  max-width: 25%;
  overflow: hidden;
}

.mdp-nav.srm-capitalize .mdp-nav-item {
  font-size: 14px;
  text-transform: capitalize;
}

.mdp-nav-item-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  width: 100%;
}

.mdp-nav-item.srm-active {
  border-bottom-color: rgb(64, 64, 64);
}

.mdp-nav-item:hover {
  background: #f3f3f3;
}

.mdp-nav-item-icon {
  margin-right: 10px;
  &.srm-no-margin {
    margin-right: 0;
  }
}

.mdp-nav-item-icon:before {
  font-size: 16px;
}

.mdp-nav.srm-sub {
  border-bottom-width: 0;
  &.srm-sub-with-border {
    .mdp-nav-wrap {
      border-bottom: 1px solid #cfcfcf;
    }
  }

  .mdp-nav-item {
    color: #757576;
    max-width: none;

    &.srm-active {
      border-bottom-color: #757576;
    }
  }
}
