#react-paginate {
  text-align: center;
  margin-top: $margin-42;
  user-select: none;
  ul {
    display: inline-block;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    li {
      display: inline-block;
      border: solid 1px transparent;
      cursor: pointer;
      margin-right: 1.6rem;
      font-size: 13px;
      a {
        color: rgb(64, 64, 64);
        display: inline-block;
        width: 25px;
      }
      a:focus {
        outline: 0;
      }

      &:hover:not(:first-child):not(:last-child):not(.active) {
        border-radius: 2px;
        border: 1px solid #d4d4d4;
      }
    }
  }
  .pagination {
    .previous,
    .next {
      a {
        font-size: 16px;
      }
      i {
        vertical-align: text-bottom;
      }
      &:hover {
        a {
          color: black;
        }
      }
    }
    .active {
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.35);
      &:hover {
        cursor: default;
      }
      //background-color: #D8D8D8;
    }
    .break a {
      cursor: default;
    }
  }
}
