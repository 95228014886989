//**** TOOLBAR
.toolbar {
  display: flex;

  .sr-logo,
  .sr-logo--mobile {
    margin-left: 16px;
  }

  .navigation {
    margin-left: auto;
    margin-right: 16px;

    &--btn {
      cursor: pointer;
      text-transform: uppercase;
      color: $color-white;
      letter-spacing: 0.5px;
      display: inline-block;
      height: 100%;
      padding: 1.2rem;
      transition: background-color 0.3s;
      font-size: 13px;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      vertical-align: top;

      &:hover {
        background-color: rgba($color-white, 0.2);
      }

      &.nav-active {
        border-bottom: 2px solid $color-white;
      }

      span {
        display: inline-block;
        margin-top: -1px;
        margin-right: 10px;
        vertical-align: middle;
        font-size: 1.6rem;
      }

      i.sr-icon-large {
        font-size: 19px;
      }
    }
  }

  .sr-logo,
  .mdp-logo {
    img {
      height: auto;
      top: 50%;
      position: relative;
      transform: translateY(-50%);
      width: 116px;
    }

    a {
      position: relative;
      display: block;
      height: 100%;
    }
  }

  .sr-logo--mobile img {
    margin-top: 8px;
    height: 36px;
  }

  .sr-logo {
    border-right: 1px solid rgba($color-white, 0.3);
    flex: 0 0 234px;
  }

  .sr-logo--mobile {
    display: none;
  }

  .mdp-logo {
    margin-left: 24px;

    img {
      width: 170px;
    }
  }

  @include respond(tab-port) {
    .sr-logo,
    .mdp-logo,
    .navigation {
      display: none;
    }
    .sr-logo--mobile {
      display: block;
    }
  }
}

.toolbar,
.sr-logo,
.mdp-logo,
.navigation {
  height: 100%;
}

// // NAVIGATION DROPDOWN
.nav-dropdown {
  position: relative;
}

.nav-dropdown:hover .nav-dropdown--content {
  display: block;
}

.nav-dropdown--content {
  text-align: left;
  display: none;
  position: absolute;
  top: 4rem;
  right: 0;
  background-color: $color-white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  min-width: 16rem;

  a {
    &,
    &:link,
    &:visited {
      color: $color-darkest-grey;
      padding: 1.2rem;
      display: block;
      transition: background-color 0.3s;
    }

    &:hover {
      background-color: $color-grey-light-2;
    }
  }

  a:not(:last-child) {
    border-bottom: 1px solid $color-grey-light-2;
  }
}

.nav-status {
  display: flex;
  align-items: center;
  padding: 2px 6px 2px 10px;
  font-size: 12px;
  margin-top: -2px;

  a {
    text-transform: capitalize;
    font-size: 13px;
    position: relative;
    cursor: auto;

    .status-label {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 30%;
    }
  }

  a.status-link {
    border-top: 0.5px solid rgba(0, 0, 0, 0.33);
    color: $color-blue-link;
    cursor: pointer;
  }
}

.nav-status:after {
  font-family: Sportradar16-3-3, sans-serif;
  content: '\e900';
  margin-left: 12px;
}
