.document__content,
.document__navigation {
  max-width: 1200px;
}
.document__navigation {
  margin: 44px auto 0 auto;
  padding-top: 11px;
  padding-left: 24px;
  height: 48px;
  width: 100%;
  button {
    height: 28px;
    padding: 0 1rem 0.9rem 1rem;
    font-size: 1.3rem;
    background-color: transparent;
    border: none;
    color: rgb(45, 45, 45);
    letter-spacing: 0.6px;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
  }
  .nav-active {
    border-bottom: 2px solid rgb(45, 45, 45);
  }
  @include respond(tab-port) {
    display: none;
  }
}

.document__content {
  margin: 0 auto;
  padding: 5rem 2.4rem 10rem 2.4rem;
  font-size: 1.8rem;
  line-height: 2.4rem;
  h1 {
    padding-bottom: 2rem;
  }
  h2,
  em {
    font-weight: 600;
  }
  h2 {
    font-size: 2rem;
    padding: 3.2rem 0 1.6rem 0;
  }

  h3 {
    padding: 2.4rem 0 0.6rem 0;
  }

  ul {
    padding: 3.2rem 2.4rem;
    li {
      font-size: 1.6rem;
    }
  }
  ol {
    padding: 3.2rem 2.4rem 1.6rem 2.4rem;
  }
  pre {
    margin-top: 0.8rem;
    margin-bottom: 1.6rem;
  }

  &_empty {
    text-align: center;

    i {
      font-size: 48px;
      color: #777777;
    }

    p {
      margin-top: 30px;
    }
  }

  .introduction {
    > ul {
      text-align: center;
      padding-top: 5.4rem;
      li {
        height: 13rem;
        line-height: 13rem;
        vertical-align: middle;
        background-color: rgb(243, 243, 243);
        width: 15.8rem;
        display: inline-block;
        list-style-type: none;
        &:not(:last-child) {
          margin-right: 3.2rem;
        }
      }
      li:first-child,
      li:nth-child(2),
      li:nth-child(3) {
        color: transparent;
        background-size: cover;
        border-radius: 4px;
        @include respond(phone) {
          display: block;
          margin: 1.6rem auto;
        }
      }
      li:first-child {
        background-image: url(../../images/introduction-01.png);
      }
      li:nth-child(2) {
        background-image: url(../../images/introduction-02.png);
      }
      li:nth-child(3) {
        background-image: url(../../images/introduction-03.png);
      }
    }

    @include clearfix;
    .features,
    .contents {
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.35);
      border-radius: 2px 2px 2px 2px;
      width: calc((100% - 2.4rem) / 2);
      float: left;
      margin-top: 5rem;
      min-height: 40rem;
      @include respond(phone) {
        width: 100%;
      }
      h2 {
        background-color: $color-sr-dark-blue;
        color: $color-white;
        text-align: center;
        font-weight: 600;
        font-size: 1.6rem;
        margin-bottom: 2.4rem;
        padding: 0;
      }
      p {
        padding: 1.6rem;
        font-weight: 600;
      }
      ul {
        padding: 0 3.2rem;
      }
      &:not(:last-child) {
        margin-right: 2.4rem;
      }
    }
  }
}
