.mdp-switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  margin-left: 8px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .mdp-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 20px;
  }

  .mdp-slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: rgba(255, 255, 255, 0.95);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .mdp-slider {
    background-color: #118330;
  }

  input:focus + .mdp-slider {
    box-shadow: 0 0 1px #118330;
  }

  input:checked + .mdp-slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
}
