.user {
  &__card {
    background: rgb(242, 242, 242);
    border-radius: 2px;
    height: 40px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.35);

    .user__icon,
    .user__name {
      display: inline-flex;
      vertical-align: top;
      overflow: hidden;
    }

    .user__icon {
      height: 40px;
      width: 40px;
      background: #07013b;
      display: inline-flex;
      color: white;
      align-items: center;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;

      i {
        font-size: 20px;
        font-weight: 400;
        width: 100%;
        text-align: center;
      }
    }

    .user__name {
      height: 100%;
      margin: 0;
      align-items: center;
      padding-left: 1.6rem;
      color: rgb(64, 64, 64);
      font-size: 1.6rem;
      font-weight: 100;
    }
  }
}
