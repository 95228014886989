// shared css
.report__table-head th:nth-child(2),
.pn__table-head > tr > th {
  cursor: pointer;
  position: relative;

  i {
    padding-left: 8px;
    font-weight: bold;

    &:last-child {
      padding-left: 0;
      margin-left: -4px;
    }

    &.srm-disabled {
      color: #9c9c9c;
    }
  }

  &:hover span {
    text-decoration: underline;
  }
}

.pn {
  &__form {
    @include clearfix;
    &-select {
      width: 100%;
      border: 1px solid #e7e7e7;
      height: 4rem;
      font-size: 1.5rem;
      color: inherit;
      background-color: $color-grey-light-3;
      margin-right: 10px;
      font-weight: $font-weight-regular;
      transition: border 0.3s;
      cursor: pointer;
      &:focus {
        outline: none;
        border: 1px solid $color-blue;
      }
    }
  }
  &__table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    font-weight: $font-weight-regular;
    margin-top: 1.2rem;
    &-head > tr {
      height: 4rem;
      text-transform: uppercase;
    }
    &-head > tr > th {
      background-color: $color-grey-light-3;
      font-weight: inherit;
      padding: 2px 8px 0;
      font-size: $font-size-14;
      color: rgb(0, 0, 0);
      span {
        top: 13px;
      }
    }
    &-body {
      tr,
      td {
        border-bottom: 1px solid $color-grey-light-border;
        font-weight: inherit;
        padding: 2px 8px 0;
        text-align: left;
        color: rgb(64, 64, 64);
        height: 4rem;
        font-size: $font-size-14;
      }
    }

    .srm-right {
      text-align: right;
    }

    td.srm-right {
      padding-right: 16px;
    }

    .item-badge {
      padding: 0 8px;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.13);
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }

      &.android {
        background: #d9e4fd;
      }
      &.ios {
        background: #d9f2dd;
      }
    }
  }
}

.report {
  &__table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    border-bottom: 1px solid #999;
    font-weight: $font-weight-regular;
    &-head {
      color: #999;
      border-bottom: 1px solid #999;
      th {
        padding-left: 0.8rem;
      }
    }
    &-row {
      white-space: nowrap;
      &:not(:last-child) {
        border-bottom: 1px solid #999;
      }
      td {
        padding-left: 0.8rem;
      }
    }

    &-detail {
      margin-left: 8px;
      display: flex;
      justify-content: space-between;
      &-text {
        flex: 1;
        padding: 0 5px;
      }
    }
  }
}

.pn-form {
  &__row {
    position: relative;
    height: 2rem;
    & > h5 {
      display: inline-block;
      vertical-align: top;
    }
    .pn-form__btn {
      background: transparent;
    }
  }
  &__label {
    font-size: 1.2rem;
    line-height: 18px;
    position: absolute;
    top: 0;
    right: 4.9rem;
  }
  &__select {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 10rem;
    font-size: 1.5rem;
    height: 2.4rem;
    border: 1px solid #e7e7e7;
    color: inherit;
    background-color: #fff;
    cursor: pointer;
    &:focus {
      outline: none;
      border: 1px solid $color-blue;
    }
  }
  &__btn {
    color: $color-blue;
    border: none;
    cursor: pointer;
    font-size: $font-size-14;
    outline: none;
    user-select: none;

    i {
      padding-right: 8px;
    }

    &:hover span {
      text-decoration: underline;
    }
  }

  &__languages {
    float: right;

    & + button {
      float: right;
      padding-right: 32px;
    }

    > span {
      padding: 0 8px;
      background: #2b5abc;
      border-radius: 24px;
      color: white;
      margin-right: 8px;
      cursor: pointer;
      user-select: none;

      &:last-child {
        margin: 0;
      }

      i {
        font-size: 18px;
        vertical-align: text-bottom;
        pointer-events: none;
      }

      &.srm-disabled {
        border: 1px solid rgba(0, 0, 0, 0.45);
        background: transparent;
        color: rgb(64, 64, 64);
      }
    }
  }
}

// TODO - make one class for select and change props on custom

.custom-pn--title {
  border-bottom: solid 1px rgba(0, 0, 0, 0.13);
  padding-bottom: 5px;
}

.custom-pn--form {
  @include clearfix;

  h3 {
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }

  select,
  input {
    width: 100%;
    height: 4rem;
    font-size: 1.5rem;
    border: 1px solid #e7e7e7;
    background-color: $color-grey-light-3;
    color: inherit;
    border-radius: 3px;
    &:focus {
      outline: none;
      border: 1px solid $color-blue;
    }
  }

  .body-5 {
    margin-bottom: 0.4rem;
  }

  input {
    padding: 0.8rem 1rem;
  }
}

.custom-pn--hr {
  height: 1px;
  width: 100%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.13);
  margin-bottom: 16px;
}

.form-btn-rounded {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 1px solid #e7e7e7;
  outline: none;
  color: rgba($color-grey-dark, 0.8);
  background-color: $color-grey-light-3;
  transition: all 0.3s;
  cursor: pointer;
  i {
    font-size: 18px;
    vertical-align: text-bottom;
  }
  &:hover {
    border: 1px solid $color-blue;
    background-color: $color-grey-light-3;
    color: $color-blue;
    box-shadow: 1px 1px 2px $color-gray;
  }
  &:disabled {
    border: 1px solid $color-color-btn-disabled;
    background-color: transparent;
    color: $color-color-btn-disabled;
    box-shadow: none;
    cursor: not-allowed;
  }
}
.form-setting-row {
  border-bottom: 1px solid $color-grey-light-3;
  padding: 4px 0;
  @include clearfix;
  button {
    border: none;
    float: right;
    cursor: pointer;
    outline: none;
    background: transparent;
    i {
      font-size: 18px;
      color: $color-red-sr;
    }
  }
}
