.section-admin-settings {
  @include clearfix;
  &:not(:last-child) {
    margin-bottom: $gutter-vertical;
  }
  .custom-row {
    float: left;
    width: calc((100% - #{$gutter-horizontal}) / 2);
    &:not(:nth-child(even)) {
      margin-right: 3.2rem;
    }
    @include respond(tab-port) {
      padding: 0;
      width: 100%;
    }
    &__button {
      margin-top: 0.8rem;
    }
  }
}
