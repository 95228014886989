.image-uploader {
  position: relative;
  transition: all 0.5s;

  input[type='file'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    border-radius: 5%;
    display: inline-block;
    color: transparent;
    cursor: pointer;
    width: 100px;
    height: 100px;

    &:hover {
      -webkit-box-shadow: inset 1px 1px 40px 10px #222;
      box-shadow: inset 1px 1px 40px 1px #222;
      transition: box-shadow 0.5s ease-in-out;
    }
  }
}
