.settings-table-filters {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  color: rgb(64, 64, 64);

  &.srm-left {
    text-align: left;
  }
  &.srm-center {
    text-align: center;
  }
  &.srm-right {
    text-align: right;
  }

  &-title {
    width: 100%;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &-items {
    margin-top: 6px;
    margin-bottom: 16px;
  }

  &-item {
    display: inline-block;
    vertical-align: top;
    height: 24px;
    line-height: 24px;
    border-radius: 23.5px;
    padding-right: 12px;
    font-size: 13px;
    border: 1px solid rgba(0, 0, 0, 0.45);
    cursor: pointer;
    margin-left: 9px;
    user-select: none;

    &:first-child {
      margin-left: 0;
    }

    i {
      margin-left: 7px;
      margin-right: 4px;
    }

    &.srm-selected {
      color: rgba(255, 255, 255, 0.87);
      background: rgb(43, 90, 188);
      border: 1px solid transparent;
    }
  }
}

.settings-table {
  width: 100%;
  display: inline-block;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-right: solid 1px transparent;

  &-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: rgb(242, 242, 242);

    &:last-child {
      border-right: none;
    }

    &.srm-key {
      background: white;
    }

    &.srm-default {
      background: rgb(217, 228, 253);
    }
  }

  &-column {
    position: relative;
    display: inline-block;
    vertical-align: top;
  }

  &-title,
  &-cell {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    border-right: 1px solid rgba(0, 0, 0, 0.13);
    padding: 0 10px;
    font-size: 13px;
  }

  &-cell {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:last-child {
      border-bottom: none;
    }

    &-text {
      pointer-events: none;

      &.srm-placeholder {
        color: lightgray;
      }
    }

    &-input {
      width: 100%;
      height: 100%;
      border: 1px solid rgb(43, 90, 188);
      outline: none;
      padding: 0 10px;
    }

    &.srm-show-input {
      padding: 0;
    }

    /*&.srm-empty-default-value {
            background: rgba(255, 0, 0, 0.24);
        }*/

    &.srm-no-hover {
      pointer-events: none;
    }

    &:not(.srm-no-hover) {
      &:hover {
        overflow: visible;
        // Note bg in case of srm-empty-key
        background: white;

        .settings-table-cell-text {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 1px;
          padding: 0 10px;
          border-radius: 2px;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.35);
          background: white;
          min-width: 100%;
          z-index: 1;
        }
      }
    }
  }
}

.settings-dialog {
  color: $color-grey-dark;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 1.6rem;
  border-radius: 2px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background: rgb(255, 255, 255);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.35);
  height: 151px;
  width: 480px;
  margin: 0 auto;
  text-align: left;

  &-header {
    background: rgb(0, 0, 60);
    height: 32px;
    line-height: 32px;
    width: 100%;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.87);
    letter-spacing: 0.5px;
    padding: 0 1.6rem;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    font-weight: 100;
    position: relative;
  }

  &-content {
    text-align: center;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    border-bottom: solid 1px rgb(222, 222, 222);
  }

  &-delete-btn {
    float: right;
    position: relative;
    top: 1.6rem;
    right: 1.6rem;
    padding: 0 2.4rem;

    &:hover,
    &.active {
      background-color: $color-grey-disabled;
    }
  }

  &-close {
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    &:hover {
      color: rgba(255, 255, 255, 1);
    }

    i {
      top: 0.8rem;
      right: 0.5rem;
      position: absolute;
      font-size: 18px;
    }
  }

  &-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
  }
}
