.cost-report {
  padding: 5rem 1.6rem;
  font-size: 1.4rem;
  line-height: 2.2rem;

  table {
    border: 1px solid $color-grey-light-border;
    width: 80%;
    border-collapse: collapse;

    thead {
      background-color: $color-sr-dark-blue;
      color: white;
      font-weight: bold;
    }

    tr {
      border-bottom: 1px solid $color-grey-light-border;
    }

    td {
      padding: 2px 8px;
      border-left: 1px solid $color-grey-light-border;
    }
  }
}
