/*

github.com style (c) Vasily Polovnyov <vast@whiteants.net>

*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #333;
  background: #f8f8f8;
  /* position: relative; */
  font-size: 14px;
}

.hljs-comment,
.hljs-quote {
  color: #998;
  font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
  color: #333;
  font-weight: bold;
}

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
  color: #008080;
}

.hljs-string,
.hljs-doctag {
  color: #d14;
}

.hljs-title,
.hljs-section,
.hljs-selector-id {
  color: #900;
  font-weight: bold;
}

.hljs-subst {
  font-weight: normal;
}

.hljs-type,
.hljs-class .hljs-title {
  color: #458;
  font-weight: bold;
}

.hljs-tag,
.hljs-name,
.hljs-attribute {
  color: #000080;
  font-weight: normal;
}

.hljs-regexp,
.hljs-link {
  color: #009926;
}

.hljs-symbol,
.hljs-bullet {
  color: #990073;
}

.hljs-built_in,
.hljs-builtin-name {
  color: #0086b3;
}

.hljs-meta {
  color: #999;
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
/* copy to clipboard*/
.clipboard-wrapper {
  width: 100%;
  position: relative;
  text-align: right;
}
.clipboard-btn {
  position: relative;
  outline: none;
  border: none;
}
.clipboard-btn:active,
.clipboard-btn:focus {
  outline: none;
}
.clipboard-btn:before {
  cursor: pointer;
  content: url('../../icons/16-346-copy.svg');
  opacity: 0.5;
}
.clipboard-btn:hover:before {
  opacity: 1;
}
.copy-label {
  position: absolute;
  color: #404040;
  opacity: 0.5;
  font-size: 12px;
  top: 20%;
  right: 30px;
}
