.replay {
  &__select {
    margin-top: 0.8rem;
  }
  &__descripton {
    margin-top: 0.8rem;
    overflow-wrap: break-word;
  }
  &__controls {
    margin-top: 1.6rem;
    display: flex;
    align-items: center;
    button {
      margin-left: auto;
    }
  }
}

.playback-text strong {
  display: block;
  margin-top: $margin-42;
}
