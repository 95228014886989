.app__detail {
  position: relative;
  background: #fbfbfb;
  border-right: solid 1px #f3f3f3;

  &-title {
    font-size: $font-size-16;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 40px;
    max-height: 40px;
    background-color: #eaeaea;
    cursor: pointer;
    text-align: center;
    width: 100%;
    display: inline-block;

    input[type='text'] {
      color: initial;
      font-size: $font-size-14;
      width: 100%;
      border: none;
      border-bottom: 2px solid red;
      height: 38px;
      top: -2px;
      position: relative;
      padding: 0 12px;

      &:focus {
        outline: none;
      }
    }
  }
  &-controls {
    &-right,
    &-left {
      display: inline-block;
      font-size: 2rem;
      transition: all 0.3s;
      &:hover {
        cursor: pointer;
        transform: scale(1.4);
      }
    }
    &-right {
      color: #138c5a;
      float: right;
    }
    &-left {
      color: red;
    }
  }
  &-icon {
    width: 100px;
    height: 100px;
    margin: 1.5rem auto 4.5rem auto;
  }
  &-content-box {
    font-size: $font-size-14;
    line-height: 1.6rem;
    padding: 0 2rem 0.8rem 2rem;
  }
  &-content-plan {
    //margin: 8px 0 8px 3px;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    border-radius: 3px;
  }
  &-expiration {
    margin-bottom: 0.8rem;
  }
  &-content-item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
  }
  &-content-desc {
    > div:last-child {
      padding: 12px 8px;
      color: #999;
    }
  }
  &-status {
    display: block;
    text-align: center;
    background-color: #fb7878;
    color: #fff;
    padding: 0.4rem 1.6rem;
    text-transform: uppercase;
    font-size: 1.4rem;
    top: -5px;
    position: relative;

    svg {
      fill: $color-white;
      display: inline-block;
      height: 1.6rem;
      margin-right: 0.6rem;
      transform: translateY(3px);
    }
  }
}
// 	TODO - check app detail and reduce code
.app-sidebar,
.app-sidebar--box,
.app-sidebar--list {
  width: 25rem;
}
.app-sidebar {
  position: fixed;
}
.app-sidebar--list {
  &.docs--list {
    height: 80vh;
    overflow-y: scroll;
  }
}
// Sidebar
.sidebar {
  &__link,
  &__button {
    list-style-type: none;
    position: relative;
    font-size: 15px;
  }

  &__link a,
  &__link a:link,
  &__link a:visited,
  &__button--label {
    cursor: pointer;
  }

  &__link--icon,
  &__button--icon {
    display: inline-block;
    font-size: 16px;
    transform: translateY(2px);
  }

  &__link {
    border-bottom: 1px solid #e7e7e7;
    padding-left: 1.6rem;
    padding-right: 1.6rem;

    a,
    a:link,
    a:visited {
      color: $color-black;
      display: block;
      //text-transform: uppercase;
      padding: 1.2rem 0;
      transition: background-color 0.3s;
    }
    &:hover:not(.active) {
      a:not(.sidebar__link--icon) {
        color: $color-blue;
      }
    }
    &.active {
      cursor: pointer;
      background: #eaeaea;
      a {
        color: $color-blue;
      }
    }
    &--icon {
      margin-right: 1.6rem;
    }

    &--text {
      vertical-align: top;
      display: inline-block;
    }
  }
  &__button {
    text-transform: uppercase;
    border-bottom: 1px solid $color-grey-disabled;
    &--checkbox,
    &--menu {
      display: none;
    }
    &--label {
      display: inline-block;
      width: 90%;
      padding-top: 1.2rem;
      padding-left: 0.8rem;
      height: 4rem;
    }
    &--label:after {
      position: absolute;
      right: 0;
      font-family: Sportradar16-3-3, sans-serif;
      content: '\e9d8';
    }
    &--checkbox:checked ~ &--menu {
      display: block;
      list-style-type: none;
      padding-left: 2.4rem;
      li {
        padding: 1.2rem 0;
        text-transform: capitalize;
        cursor: pointer;
        transition: all 0.3s;
      }
      .selected,
      li:hover {
        font-weight: bold;
      }
    }
    &--checkbox:checked ~ &--label:after {
      font-family: Sportradar16-3-3, sans-serif;
      content: '\e9d7';
    }
  }
}
// documentation sidebar
.docs-sidebar {
  padding: 44px 0;
}
