.element {
  display: flex;
  column-gap: 32px;
  align-items: center;

  .color {
    width: 16px;
    height: 16px;
  }
}
