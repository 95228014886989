.sr-forms {
  margin: 0 auto;

  &-content {
    text-align: center;
    margin: auto;
    padding: 3rem 0;
  }

  &-component {
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  }

  &-title {
    height: 40px;
    width: 100%;
    background: #00013c;
    text-align: left;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;

    .sr-logo {
      width: 116px;
      overflow: hidden;
      height: 100%;
      display: inline-block;
      vertical-align: top;
      margin-left: 32px;

      a {
        height: 100%;
        width: 100%;
        display: block;
      }

      img {
        width: 100%;
        height: auto;
        top: 50%;
        transform: translateY(-50%);
        position: relative;
      }
    }

    .sr-forms-mdp-brand {
      display: inline-block;
      vertical-align: top;
      color: white;
      padding-left: 16px;
      line-height: 40px;
    }
  }

  &-form {
    width: 46rem;
    padding: 2.4rem 3rem 3rem 3rem;
    margin-bottom: 1.6rem;
    text-align: left;

    @include respond(phone) {
      width: 100%;
    }

    @include respond(tablet) {
      width: 100%;
    }

    label {
      display: inline-block;
      margin-top: 2.8rem;
    }

    input[type='checkbox'] + label {
      margin-top: 0;
      padding-bottom: 6px;
      font-size: 13px;
    }

    p {
      font-size: 1.6rem;
      text-align: left;
    }

    h3 {
      font-size: 24px;
      margin: 0;
    }

    button {
      font-weight: 100;
      background: #2b5abc;
    }

    input {
      background: #fff;
      border-radius: 3px;
    }

    input[type='checkbox'] {
      margin-right: 8px;
      margin-top: 4px;
    }

    input.srm-empty {
      background: rgba(0, 0, 0, 0.07);
    }

    textarea {
      min-height: 7.2rem;
      resize: none;
      padding: 1.2rem;
      border-radius: 2px;
      font-size: 1.5rem;

      &:focus {
        outline: none;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      border: 1px solid rgba(0, 0, 0, 0.45);
      -webkit-text-fill-color: rgb(64, 64, 64);
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    input:-webkit-autofill:focus {
      border: 1px solid #0052cc;
    }

    .srm-empty {
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus {
        border: 1px solid rgba(0, 0, 0, 0.45);
        -webkit-text-fill-color: rgb(64, 64, 64);
        -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.07) inset;
        transition: background-color 5000s ease-in-out 0s;
      }

      input:-webkit-autofill:focus {
        border: 1px solid #0052cc;
      }
    }

    .srm-empty + .sr-forms-input-password-icon {
      display: none;
    }

    .xm-empty + .sr-forms-input-password-icon {
      display: none;
    }

    .sr-forms-input-password-icon {
      cursor: pointer;
      position: absolute;
      font-size: 20px;
      right: 4px;
      bottom: 10px;

      &:hover {
        color: #0052cc;
      }
    }

    .sr-forms-input-container {
      position: relative;
    }
  }

  .input-error {
    height: 24px;
    line-height: 24px;
    background: rgb(255, 217, 217);
    color: black;
    font-size: 12px;
    padding: 0 16px 0 32px;
    border-radius: 3px;
    position: absolute;
    width: 100%;
    box-sizing: border-box;

    &-icon {
      background: #e01d15;
      color: white;
      border-radius: 14px;
      width: 14px;
      height: 14px;
      font-size: 11px;
      text-align: center;
      position: absolute;
      top: 5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      left: 8px;
      font-weight: bold;
    }
  }

  &-links {
    > a {
      padding-right: 32px;
      font-size: 13px;

      &:last-child {
        padding-right: 0;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
