// shared
.users__box-row,
.control-wrapper,
.control-wrapper .users__role-controls {
  display: flex;
}
.users__box-row,
.control-wrapper .users__role-controls {
  align-items: center;
}

.users {
  &__box {
    @include respond(tab-port) {
      padding: 0;
    }
    &-heading {
      background-color: $color-grey-light-2;
      &-primary,
      &-secondary {
        display: inline-block;
        font-size: 1.5rem;
        text-transform: uppercase;
        padding: 1.2rem 0.6rem 1rem 0.6rem;
        letter-spacing: 0.5px;
        font-weight: 100;
        width: calc(100% - 235px);
      }
      &-secondary {
        width: 235px;
        text-transform: initial;
        //text-align: right;
      }
    }
    &-row {
      @include clearfix;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      font-size: 1.4rem;
      padding: 0.6rem 0;
    }
    &-col-email,
    &-col-role {
      width: calc(100% - 235px);
      float: left;
      padding: 0 0.6rem;
    }

    &-col-role {
      width: 235px;
      font-weight: 100;
      text-transform: capitalize;
      //text-align: right;

      .sr-btn,
      select {
        height: 2.4rem;
        font-size: 12px;
        line-height: 2.4rem;
      }
    }
  }
}
.settings__row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 1.2rem;
}
.control-wrapper {
  text-transform: uppercase;
  .users__role-select {
    flex-basis: 50%;
    @include respond(phone) {
      flex-basis: 100%;
    }
  }
  .users__role-controls {
    flex-basis: 50%;
    padding-left: 1.2rem;
    margin-right: 0;
    margin-left: auto;

    div {
      flex-basis: 50%;
    }
    div:nth-child(2) {
      button {
        margin-left: auto;
      }
      button:hover {
        color: $color-red-sr;
      }
      i {
        font-size: 18px;
      }
    }
  }
}
