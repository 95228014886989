.login-wrapper {
  padding: 32px;
  margin-bottom: 16px;

  .logo {
    margin: 0 auto 24px;
  }

  .primary-button {
    width: 100%;
    max-width: 300px;
    margin: 0 auto 16px;
    font-size: 15px;
    line-height: 19px;
  }

  .manual-login {
    color: $color-blue;
    margin-top: 16px;
  }
}

.sr-forms-input-container {
  position: relative;

  .toggle-password {
    position: absolute;
    right: 8px;
    top: 38px;

    &:hover {
      cursor: pointer;
    }
  }
}
