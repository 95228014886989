@import url('https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css');

@font-face {
  font-family: 'SRFont', sans-serif;
  src: url(../../fonts/Sportradar16-3-3/fonts/Sportradar16-3-3.ttf)
    format('ttf');
  src: url(../../fonts/Sportradar16-3-3/fonts/Sportradar16-3-3.woff)
    format('woff');
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  // check if we will increase/decrease font sizes on breakpoint
  // @include respond(tab-land) { // width < 1200?
  //     font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
  // }

  // @include respond(tab-port) { // width < 900?
  //     font-size: 50%; //1 rem = 8px, 8/16 = 50%
  // }

  // @include respond(big-desktop) {
  //     // 75% => 1 rem = 12px
  //     font-size: 62.5%; //1rem = 12, 12/16
  // }
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  box-sizing: border-box;
  // @include respond(tab-port) {
  //     padding: 0;
  // }
}

html,
body,
#root {
  min-height: 100vh;
}

.primary-button {
  background: $color-blue;
  border-radius: 2px;
  color: white;
  padding: 10.5px 0;
  border: none;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.input-form {
  width: 100%;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.45);
  box-sizing: border-box;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  font-size: 14px;
  line-height: 18px;
  padding-left: 8px;
  font-family: 'Source Sans Pro', sans-serif;
}

.login-button {
  background: $color-blue;
  border-radius: 2px;
  color: white;
  padding: 10.5px 0;
  border: none;
  width: 100%;
}

.login-button:hover {
  cursor: pointer;
}

.error-toast {
  color: red;
  font-size: 13px;
  margin: 24px 0 0;
  display: flex;
}

.error-toast img {
  margin: 0 8px 0 6px;
}
