// shared css
.applist__box,
.app__icon,
.app__status svg {
  position: relative;
}
.app__plan,
.app__status,
.create-app__text {
  text-transform: uppercase;
}

.app__description h6 .app__description p,
.app__plan h6,
.app__expiration h6,
.app__expiration p {
  font-size: 1.3rem;
}
.app__description p,
.app__expiration p {
  color: $color-grey-dark;
}

.applist {
  &__wrapper {
    @include clearfix;
  }
  &__box {
    background-color: $color-white;
    border: 1px solid rgba($color-grey-dark, 0.2);
    box-shadow: 0 1px 3px 0 rgba($color-grey-dark, 0.15);
    border-radius: 2px;
    width: 16.8rem;
    height: 21.2rem;
    float: left;
    margin-right: $gutter-horizontal;
    margin-bottom: $gutter-vertical;
    padding: 0.6rem 0.6rem 1.2rem 0.6rem;
    transition: all 0.075s linear;
    text-align: center;
    &:hover {
      &:not(.applist__invite) {
        cursor: pointer;
        box-shadow: 1px 2px 5px 0 rgba($color-grey-dark, 0.6);
        transition: 75ms;
        border: 1px solid rgba($color-grey-dark, 0.5);
      }
    }
    &:link,
    &:visited {
      color: inherit;
    }
    @include respond(phone) {
      margin-right: 1rem;
    }
    h5 {
      font-weight: 100;
      border-bottom: 1px solid rgba($color-grey-dark, 0.075);
      margin: 6px 0;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      padding: 0 10px 6px 10px;
    }
  }
}
.app {
  &__icon {
    height: 9.8rem;
    width: 9.8rem;
    margin: 0 auto;
    svg {
      height: 4.9rem;
      width: 6.3rem;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      height: 9.6rem;
      width: 9.6rem;
      border-radius: 3px;
    }
  }
  &__plan {
    button {
      padding: 0.4rem 0.6rem;
      width: 100%;
      margin-bottom: 1rem;
      cursor: pointer;
      border: none;
    }
    h6 {
      border-right: 1px solid rgba($color-grey-dark, 0.075);
    }
  }
  &__plan-type {
    display: block;
    border-radius: 2px;
    margin: 0 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__expiration {
    p {
      line-height: 1.4rem;
      margin-top: 3px;
    }
  }

  &__status_wrap {
    border-top: 1px solid rgba(64, 64, 64, 0.075);
    width: 100%;
    height: 50px;
    margin-top: 8px;
  }

  &__status {
    background-color: $color-red-sr;
    color: $color-white;
    position: absolute;
    text-align: center;
    padding: 0 1.6rem;
    left: 20px;
    border-radius: 2px;
    font-size: 12px;
    right: 20px;
    bottom: 17px;

    svg {
      fill: $color-white;
      display: inline-block;
      height: 1.6rem;
      margin-right: 0.6rem;
      transform: translateY(3px);
    }
  }

  &__plan,
  &__expiration {
    width: 50%;
    display: inline-block;
    text-transform: initial;
    vertical-align: top;
    margin-top: 8px;
    border-top: 1px solid rgba($color-grey-dark, 0.075);
    padding-top: 5px;

    h6 {
      text-align: center;
      font-weight: 600;
      font-size: 12px;
    }
  }
}
.create-app {
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  position: relative;
  height: 10.5rem;
  &__text {
    color: #0c0f22;
    font-size: 1.6rem;
    letter-spacing: 0.5px;
    line-height: 2rem;
    margin-top: 1.8rem;
  }
}
