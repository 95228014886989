.app-devices {
  h3 {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 600;
    margin-top: 45px;
    margin-bottom: 16px;

    &.active-playbacks {
      margin-top: 44px;
    }
  }
  &-title {
    &.srm-registered {
      margin-top: 28px;
    }
  }

  &-warning {
    font-size: 16px;
    margin-top: 32px;
    color: #a1a1a1;
    font-weight: 100;
    display: block;

    a:hover {
      text-decoration: underline;
    }
  }

  &-description {
    color: rgb(64, 64, 64);
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 35px;
  }

  &-info,
  &-controls {
    padding: 0 6px;
    width: 50%;
    height: 24px;
    display: inline-block;
    text-align: right;
    font-size: 12px;
    text-transform: uppercase;
    color: darkgrey;

    > span {
      padding: 0 7px;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &-info {
    text-align: left;

    em {
      font-style: normal;
      color: #929292;
      font-weight: 600;
    }
  }

  &-filters {
    padding: 20px;
    box-shadow: 1px 1px 3px #a9a9a9;

    &-info {
      color: #ad555e;
      width: 100%;
      text-align: center;
      opacity: 0;
      transition: 100ms;

      &.srm-visible {
        transition: 100ms;
        opacity: 0.7;
      }
    }
  }

  &-table {
    font-size: 13px;

    &.srm-playbacks {
      .app-devices-table {
        &-header > span,
        &-body-row > span {
          width: 15%;
          padding-right: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:first-child {
            width: 40%;
          }
        }
      }
    }

    &.srm-available {
      margin-bottom: 44px;

      .app-devices-table {
        &-header > span,
        &-body-row > span {
          width: 28.5%;

          &:last-child {
            width: 14.2%;
          }
        }
      }
    }

    &-header {
      background: rgb(242, 242, 242);
      height: 42px;
      line-height: 42px;
      border: solid 1px rgb(222, 222, 222);

      > span {
        color: rgb(0, 0, 0);
        width: 10%;
        text-transform: uppercase;
        display: inline-block;
        vertical-align: top;
        padding: 0 8px;

        &:nth-child(1),
        &:nth-child(2) {
          width: 20%;
        }
      }
    }

    &-body {
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto;
      border-bottom: solid 1px rgb(222, 222, 222);

      &-row {
        height: 40px;
        line-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: solid 1px rgb(222, 222, 222);
        border-top: none;

        &:last-child {
          border-bottom: none;
        }

        > span {
          color: rgb(64, 64, 64);
          width: 10%;
          display: inline-block;
          vertical-align: top;
          padding: 0 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:nth-child(1),
          &:nth-child(2) {
            width: 20%;
          }

          i {
            vertical-align: text-top;
            font-size: 16px;
            color: rgb(43, 90, 188);
          }

          &:first-child {
            i {
              padding-left: 12px;
            }
          }

          &.srm-push-token i,
          &.srm-device-name i {
            transform: scale(1);
            transition: 150ms;
            position: relative;
            cursor: pointer;

            &:hover {
              transform: scale(1.1);
              transition: 150ms;
            }
          }

          &.srm-push-token {
            position: relative;

            i {
              margin-left: 12px;
              color: #2b5abc;
            }

            .srm-push-token-tooltip {
              border-radius: 2px;
              padding: 4px 8px;
              background: rgba(181, 229, 190, 0.5);
              color: rgba(0, 0, 0, 0.5);
              font-size: 11px;
              letter-spacing: 0.5px;
              line-height: 14px;
              text-align: center;
              text-transform: uppercase;
              user-select: none;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              opacity: 0;
              margin-left: 18px;
              transition: 150ms;

              &:before {
                position: absolute;
                left: -10px;
                top: 1px;
                bottom: 0;
                content: '';
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-right: 10px solid rgba(181, 229, 190, 0.5);
              }

              &.srm-visible {
                opacity: 1;
                transition: 150ms;
              }
            }
          }
        }
      }

      .checkbox-control {
        display: block;
        position: relative;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        input:checked ~ .check {
          background-color: rgb(33, 131, 48);
          border-color: rgb(33, 131, 48);

          &:after {
            display: block;
          }
        }

        .check {
          position: absolute;
          top: 11px;
          left: 50%;
          transform: translateX(-50%);
          height: 15px;
          width: 15px;
          border-radius: 2px;
          border: 1px solid rgba(0, 0, 0, 0.45);

          &:after {
            content: '';
            position: absolute;
            display: none;
            left: 5px;
            top: 2px;
            width: 4px;
            height: 8px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        &:hover input ~ .check {
          border: 1px solid rgb(33, 131, 48);
        }
      }
    }
  }

  .srm-link {
    color: rgb(43, 90, 188);
    cursor: pointer;
    user-select: none;

    &:not(:first-child) {
      margin-left: 12px;
    }

    &:hover {
      text-decoration: underline;
    }

    &.srm-disabled {
      color: darkgrey;
      text-decoration: none;
      pointer-events: none;
    }
  }

  &-tile {
    margin: 88px 0;
    background: rgb(255, 255, 255);
    border-radius: 2px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.35);

    &-header {
      padding: 0 16px;
      background: rgb(242, 242, 242);
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      letter-spacing: 0.5px;
      color: rgb(0, 0, 0);

      > span {
        width: 50%;
        display: inline-block;
        vertical-align: top;

        &:last-child {
          text-align: right;
        }
      }
    }

    .MuiCheckbox-colorSecondary {
      &:hover {
        background-color: rgba(17, 131, 48, 0.06);
      }
      &.Mui-checked {
        color: rgb(17, 131, 48);
        &:hover {
          background-color: rgba(17, 131, 48, 0.06);
        }
      }
    }
  }

  &-multiselect {
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.45);
    height: 122px;
    overflow: hidden;
    overflow-y: auto;

    &-row {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid rgb(222, 222, 222);
    }
  }

  .qr-code-wrapper {
    width: min-content;
    margin: 0 auto 20px;

    .qr-code {
      margin: 0 auto;
      display: block;
    }

    .scanned {
      margin: 0 auto 10px;
    }

    .countdown {
      width: max-content;
    }
  }
}

.srm-center {
  text-align: center;
}

.mdp-modal {
  transition: 250ms;
  background: rgb(255, 255, 255);
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.35);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;

  &-body {
    padding: 20px 16px 24px 16px;
  }

  &-overlay {
    transition: 250ms;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 199;
  }

  &-header {
    width: 100%;
    background: rgb(0, 0, 60);
    height: 32px;
    line-height: 32px;
    color: rgba(255, 255, 255, 0.87);
    font-size: 16px;
    letter-spacing: 0.5px;
    text-align: center;
  }

  &-close-x {
    color: white;
    width: 34px;
    text-align: center;
    line-height: 32px;
    position: absolute;
    right: 0;
    font-size: 16px;

    &:hover {
      color: rgba(255, 255, 255, 1);
      text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5);
    }
  }

  &-input-container {
    margin-top: 20px;
  }

  &-button-container {
    text-align: right;

    &.srm-center {
      text-align: center;
    }

    &.srm-standalone {
      > button {
        margin: 0 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &-button {
    border-radius: 2px;
    border: 1px solid rgb(43, 90, 188);
    line-height: 32px;
    height: 32px;
    padding: 0 8px;
    color: rgb(43, 90, 188);
    font-size: 14px;
    letter-spacing: 0.5px;
    margin-top: 24px;
    opacity: 0.4;
    pointer-events: none;
    user-select: none;
    background: white;
    cursor: pointer;

    &.srm-active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &-input {
    height: 40px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.07);
    border: 1px solid rgba(0, 0, 0, 0.45);
    outline: none;
    cursor: pointer;
    font-size: 15px;
    color: rgb(64, 64, 64);
    padding: 0 8px;
    width: 100%;

    &.srm-disabled {
      color: rgba(64, 64, 64, 0.5);
      border: none;
    }
  }

  &-label {
    display: block;
    color: rgb(64, 64, 64);
    font-size: 13px;
  }
}

.playback-control,
.push-notification-control {
  margin-top: 22px;
  background: rgb(242, 242, 242);

  &-body {
    display: flex;
  }

  &-title {
    text-transform: uppercase;
    border-bottom: solid 1px #dedede;
    padding-bottom: 7px;
    margin-bottom: 16px;
    font-weight: 600;
  }

  label {
    color: rgb(64, 64, 64);
    font-size: 13px;
    margin-bottom: 4px;
    display: block;
  }

  select {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.45);
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 30%;
    margin-right: 32px;

    &:disabled {
      border: solid 1px gray;
      pointer-events: none;
      user-select: none;
    }
  }

  input[type='text'] {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.45);
    height: 40px;
    width: 100%;
    color: rgb(64, 64, 64);
    font-size: 15px;
    padding: 0 8px;

    &:focus {
      outline: none;
      border: 1px solid #2b5abc;
    }
  }

  &-body > span {
    display: inline-block;
  }

  &-button {
    display: inline-block;
    padding: 0 14px;
    border-radius: 3px;
    font-size: 15px;
    outline: none;
    cursor: pointer;
    line-height: 40px;
    height: 40px;
    border: none;
    text-transform: uppercase;

    i {
      margin-right: 6px;

      &:before {
        position: relative;
        top: 1px;
      }
    }

    &-text {
      position: relative;
      transform: scale(1);
      transform-origin: center;
      transition: 100ms;
      display: inline-block;
    }

    &.srm-disabled {
      border: solid 1px gray;
      background: transparent;
      color: gray;
      cursor: default;
      i {
        cursor: default;
      }
    }

    &:hover:not(.srm-disabled) {
      .playback-control-button,
      .push-notification-control-button {
        &-text {
          transform: scale(1.04);
          transition: 100ms;
        }
      }
    }
  }
}

.playback-control {
  margin-top: 0;
  padding: 12px 15px 15px 15px;

  &-button {
    color: rgba(255, 255, 255, 0.87);
    background: rgb(17, 131, 48);
    transform: scale(1);
    transition: 100ms;
    width: 16%;
    position: relative;

    &.srm-stop {
      background: #df4554;
    }
  }

  &-slider {
    opacity: 0.6;
    pointer-events: none;
    cursor: default;
    display: inline-block;
    width: 41%;
    margin-right: 1%;

    &.srm-active {
      opacity: 1;
      pointer-events: all;
      cursor: pointer;
    }

    .slider__input {
      appearance: none;
      min-width: 200px;
      -webkit-appearance: none;
      width: 100%;
      height: 0.8rem;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      outline: none;

      &:hover {
        &::-webkit-slider-thumb {
          transform: scale(1.1);
          transition: 100ms;
        }
      }

      &::-webkit-slider-thumb {
        width: 2rem;
        height: 2rem;
        background-color: #118330;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 50%;
        cursor: pointer;
        transform: scale(1);
        transition: 100ms;

        &:active {
          transform: scale(1.1);
          transition: 100ms;
        }

        &:hover {
          transform: scale(1.1);
          transition: 100ms;
        }
      }
    }
  }

  &-duration {
    width: 82%;
    margin-right: 1%;
    display: inline-block;
    vertical-align: top;
  }

  select {
    width: 41%;
    margin-right: 1%;
    padding: 0 25px 0 10px;
  }

  &-body.srm-no-select {
    select {
      display: none;
    }

    .playback-control-slider {
      width: 83%;
    }
  }

  &-info {
    padding-top: 22px;
    opacity: 0.7;
    display: flex;
    text-align: center;
    border-top: solid 1px #dedede;
    margin-top: 13px;

    > span {
      flex-grow: 1;
    }
  }
}

.push-notification-control {
  padding: 12px 15px 25px 15px;
  border-bottom: none;

  &-body {
    > span {
      width: 41%;
      margin-right: 1%;
    }

    > em {
      display: inline-block;
      vertical-align: top;
      padding: 3px 22px;
      color: gray;
      font-style: normal;

      a {
        cursor: pointer;
        text-decoration: underline;
      }

      &.push-notification-info-no-devices {
        position: relative;
        bottom: -15px;
        left: 5px;
        padding: 0;
      }
    }
  }

  &-button {
    max-width: 25%;
    margin-right: 1%;
    border: solid 1px rgb(43, 90, 188);
    background: transparent;
    color: rgb(43, 90, 188);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.srm-disabled {
      border-color: gray;
      color: gray;
      cursor: default;
    }
  }

  &-helper-text {
    width: 36%;
    color: rgb(64, 64, 64);
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
  }

  &-dialog {
    &-body {
      padding-bottom: 18px;
    }
  }
}

.srm-badge {
  border-radius: 2px;
  padding: 4px 8px;
  background: rgb(255, 181, 181);
  color: rgb(0, 0, 0);
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  user-select: none;

  &.srm-green {
    background: rgb(181, 229, 190);
  }
}
