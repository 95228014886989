.day-picker-wrapper {
  position: fixed;

  .rdp {
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    left: 0;
    position: absolute;
    z-index: 1;
    margin: 0;
    padding: 8px;
    --rdp-cell-size: 30px !important;
  }
}
