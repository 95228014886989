.slider-wrapper {
  .slider__title {
    font-size: $font-size-16;
  }
  .slider__input {
    margin-top: 2.8rem;
    margin-top: 1.6rem;
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 0.8rem;
    background-color: rgba(0, 0, 0, 0.1);
    outline: none;
    &::-webkit-slider-thumb {
      width: 2rem;
      height: 2rem;
      background-color: $color-blue;
      -webkit-appearance: none;
      appearance: none;
      border-radius: 50%;
      cursor: pointer;
      &:active {
        transform: scale(1.1);
      }
    }
  }
}
