// color buttons
.btn {
  &-red {
    background-color: $color-red-sr;
  }

  &--grey,
  &--disabled {
    background-color: $color-grey-light-2;
    color: $color-darkest-grey;
  }

  &--disabled {
    opacity: 0.6;
  }

  &-dark-blue {
    background-color: $color-sr-dark-blue;
  }

  &-green {
    background-color: $color-affirmative-green;
  }

  &-red,
  &-dark-blue,
  &-green {
    color: $color-white;
  }

  &-red:hover,
  &--grey:hover,
  &--disabled:hover,
  &-dark-blue:hover,
  &-green:hover {
    opacity: 0.6;
  }
}

.btn-icon {
  height: 4rem;
  width: 4rem;
  border: none;
  padding-top: 0.1rem;
  border-radius: 2px;
  cursor: pointer;

  i {
    font-size: 2rem;
  }

  &:focus {
    outline: 0;
  }
}

.icon__btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;

  &--large {
    font-size: 2.2rem;
  }

  &--red {
    color: $color-red-sr;

    &:hover {
      color: $color-grey-dark;
    }
  }

  &:focus {
    outline: none;
  }
}

// ************
.btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
}

.sr-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 1.8rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;

  &__sm {
    height: 24px;
    line-height: 24px;
    padding: 0 8px;
    font-size: 13px;
    font-weight: 100;

    i {
      vertical-align: text-bottom;
    }
  }

  &__md {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    padding: 0 10px;
    font-weight: 100;

    i {
      font-size: 18px;
      line-height: 18px;
      vertical-align: text-bottom;
    }
  }

  &__lg {
    font-size: 15px;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;

    i {
      font-size: 20px;
      line-height: 20px;
      vertical-align: text-bottom;
    }
  }

  &__sm i,
  &__lg i,
  &__md i {
    margin-right: 6px;
  }

  &:focus {
    outline: 0;
  }

  &:disabled,
  &.btn-default:disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }

  &.btn-default,
  &.btn-cancel {
    &:hover {
      background-color: rgba($color-grey-dark, 0.1);
    }
  }

  &.btn-default {
    border: 1px solid $color-blue;
    color: $color-blue;
    background-color: transparent;

    &:disabled {
      border: 1px solid rgba($color-grey-dark, 0.5);
      color: $color-grey-dark;
      opacity: 0.5;
      background-color: $color-white;
      -webkit-box-shadow: 1px 1px 2px 0.5px rgba($color-black, 0.1);
      box-shadow: 1px 1px 2px 0.5px rgba($color-black, 0.1);
    }
  }

  &.btn-cancel {
    color: $color-grey-dark;
    border: none;
    margin-left: auto;
    margin-right: 8px;
  }

  &.sr-btn-fill {
    background: #2b5abc;
    color: rgba(255, 255, 255, 0.87);

    &:hover:not(:disabled) {
      background: rgba(43, 90, 188, 0.8);
    }
  }

  &.sr-btn-white {
    border: 1px solid rgba(255, 255, 255, 0.45);
    color: rgba(255, 255, 255, 0.87);
    i {
      color: rgba(255, 255, 255, 0.87);
    }
  }
}
