.select {
  width: 100%;
  border: 1px solid #b3b3b3;
  height: 4rem;
  font-size: 1.5rem;
  color: inherit;
  font-weight: $font-weight-regular;
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 3px;
  padding: 0 10px;
  background: url(../../images/br_down.png) no-repeat calc(100% - 6px) center
    $color-white;
  background-size: 10px;

  &:focus {
    outline: none;
    border: 1px solid $color-blue;
    background-color: $color-white;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: $color-grey-light-2;
    border: none;
    color: rgba(64, 64, 64, 0.5);
    &:hover {
      background-color: $color-grey-light-2;
    }
    &:active {
      border: none;
    }
  }
  &-grey {
    background-color: $color-grey-light-2;
  }
}
