.mdp-loader {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  visibility: hidden;
  pointer-events: none;

  &.srm-loading {
    visibility: visible;
  }

  .mdp-loader-spinner {
    width: 50px;
    height: 60px;
    text-align: center;
    font-size: 10px;
  }

  .mdp-loader-spinner > div {
    background-color: rgba(193, 193, 193, 0.5);
    margin: 0 1px;
    height: 100%;
    width: 6px;
    display: inline-block;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .mdp-loader-spinner .rect2 {
    animation-delay: -1.1s;
  }

  .mdp-loader-spinner .rect3 {
    animation-delay: -1s;
  }

  .mdp-loader-spinner .rect4 {
    animation-delay: -0.9s;
  }

  .mdp-loader-spinner .rect5 {
    animation-delay: -0.8s;
  }

  &.srm-inside {
    bottom: auto;
  }
  &.srm-inside .mdp-loader-image {
    margin-top: 0;
  }
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
