.layout {
  &__header {
    background-color: $color-sr-dark-blue;
    color: $color-white;
    height: $toolbar-height;
    position: fixed;
    width: 100%;
    z-index: 5;
    @include respond(tab-port) {
      height: $toolbar-height-mobile;
    }
  }
  &__content {
    display: flex;
  }
  &__sidebar,
  &__main {
    background-color: $color-grey-light-3;
    padding-top: $toolbar-height;
  }
  &__content.srm_notification {
    .layout__sidebar,
    .layout__main {
      padding-top: $toolbar-height * 2;
    }
  }
  &__sidebar {
    background-color: $color-grey-light-2;
    flex: 0 0 250px;
    @include respond(tab-port) {
      display: none;
    }
  }
  &__main {
    flex: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    @include respond(tab-port) {
      padding-top: $toolbar-height-mobile;
    }
  }
  &__footer {
    margin-top: auto;
  }
}

.content__wrapper {
  max-width: 1200px;
  margin: 0 auto;
}
