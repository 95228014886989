.recordings-table {
  border: 1px solid rgba($color-darkest-grey, 0.2);
  background-color: $color-white;
  width: 100%;
  border-spacing: 0;
  thead {
    tr {
      font-size: 1.6rem;
      text-align: left;
      box-shadow: 0 2px 2px -2px rgba($color-black, 0.5);
      th {
        padding: 0.8rem;
        font-weight: 400;
        color: rgba($color-black, 0.5);
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      font-size: 1.5rem;

      &:not(:last-child) {
        box-shadow: 0 2px 2px -2px rgba($color-black, 0.5);
      }
      td {
        padding: 0.8rem;
        color: rgba($color-black, 0.85);
        max-width: 100px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &:hover td {
        background: rgba($color-grey-light-2, 0.7);
      }
    }
  }

  td:nth-child(1) {
    width: 10%;
  }
  td:nth-child(2) {
    width: 7%;
  }
  td:nth-child(3) {
    width: 10%;
  }
  td:nth-child(4) {
    width: 21%;
  }
  td:nth-child(5) {
    width: 20%;
  }
  td:nth-child(6) {
    width: 12%;
  }
  td:nth-child(7) {
    width: 12%;
  }
  td:nth-child(8) {
    width: 7%;
  }
}
