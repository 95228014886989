.section-recording {
  padding-bottom: 50px;

  h3 {
    .sr-btn {
      float: right;
    }
  }

  .col {
    width: 48%;
    display: inline-block;
    vertical-align: top;
    margin-right: 4%;

    &.right {
      margin: 0;
    }
  }

  &-set-collapsed,
  &-offset-slider {
    margin-top: 30px;
    box-sizing: border-box;
    background: #f2f2f2;
    border-radius: 3px;
    padding: 20px;

    h6 {
      margin-bottom: 15px;
    }

    input {
      margin-bottom: 15px;
    }
  }

  .srm-disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .json-preview {
    margin-top: 40px;

    .react-json-view {
      padding: 20px;
      border-radius: 3px;
      margin-top: 20px;
    }

    .mdp-loader {
      margin-top: 100px;
      margin-bottom: 100px;
    }
  }

  .recording-edit .sr-btn {
    margin-left: auto;
  }

  table {
    width: 100%;
    border-spacing: 0;

    thead tr td {
      padding: 0 20px;
      line-height: 38px;
      background: #f2f2f2;
      font-size: 14px;
      font-weight: bold;
      border: solid 1px #cdcdcd;

      .recording-close,
      .recording-edit {
        float: right;
        line-height: inherit;
        font-weight: normal;
        cursor: pointer;

        &:hover {
          color: #4183c4;
        }

        i {
          vertical-align: text-bottom;
          padding-left: 8px;
          font-size: 18px;
        }
      }
    }

    tbody tr {
      td {
        line-height: 32px;
        height: 32px;
        padding: 0 20px;
        border-bottom: solid 1px #cdcdcd;
        border-left: solid 1px #cdcdcd;
        position: relative;

        // Note: max width on td just let us have
        // text ellipsis without extra dom elements
        max-width: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:last-child {
          border-right: solid 1px #cdcdcd;
        }

        &.srm-fixed-30 {
          width: 30%;
        }
      }

      td.srm-center,
      td.srm-center a {
        text-align: center;
      }
    }

    tbody tr.srm-selected td {
      background: #f7f7f7;

      &:last-child a {
        color: indianred;
      }
    }

    input {
      height: calc(100% - 6px);
      width: 100%;
      padding: 0 10px;
    }
  }

  table.srm-hover div table tr {
    cursor: pointer;

    &:hover td {
      background: #f7f7f7;

      a {
        text-decoration: underline;
      }
    }
  }

  table.srm-close thead tr td {
    padding-right: 10px;
  }

  table.srm-info,
  table.srm-edit {
    thead tr td {
      padding-right: 10px;
    }

    tbody tr td:last-child {
      span {
        padding: 0 10px;
      }
    }
  }

  table.srm-table-scroll > tbody > tr > td {
    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }

    padding: 0;

    > div {
      overflow-y: scroll;
      margin-bottom: -1px;
      max-height: 495px;
    }
  }

  .recording-applications {
    margin-top: 30px;
  }

  // Material overrides
  .MuiFormControl-root {
    height: calc(100% - 3px);
    width: 100%;
  }

  .MuiInput-root {
    height: 100%;
  }
}
