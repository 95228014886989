.section-terms {
  max-width: $grid-width;
  margin: 32px auto;
  &-heading {
    padding: 3.2rem 9.6rem 0 9.6rem;
    margin-bottom: $margin-42;
    p {
      font-size: 1.6rem;
    }
  }
  &-content {
    padding: 3rem 10rem;
    h2 {
      font-weight: 600;
      padding: 1.6rem 0;
    }
    h3 {
      font-weight: 400;
      padding-bottom: 0.6rem;
    }
    h2,
    h3 {
      text-transform: uppercase;
    }
    p {
      margin-bottom: 2.4rem;
    }
    ul {
      padding-left: 4.8rem;
    }
    a {
      color: $color-red-sr;
    }
  }
}
