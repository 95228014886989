.platform {
  &__add {
    display: table;
    background-color: $color-grey-light-2;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    &-icon,
    &-text {
      display: table-cell;
    }
    &-text,
    &-dialog {
      text-align: center;
    }
    &-icon {
      color: #b7b7b8;
      width: 20%;
    }
    &-text {
      font-size: 2rem;
      text-align: center;
      vertical-align: middle;
      text-transform: uppercase;
      -webkit-transform: translateX(-10%);
      transform: translateX(-10%);
    }
    &:hover,
    &-dialog-btn,
    &-dialog-btn-active {
      cursor: pointer;
    }

    &:hover {
      background-color: rgba($color-grey-dark, 0.1);
    }

    &-dialog {
      color: $color-grey-dark;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      font-size: 1.6rem;
      border-radius: 2px;
      position: relative;
      top: 50%;
      //left: 50%;
      //transform: translate3d(-50%, -50%);
      transform: translateY(-50%);
      background: rgb(255, 255, 255);
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.35);
      height: 251px;
      width: 480px;
      margin: 0 auto;
      text-align: left;

      &--header {
        background: rgb(0, 0, 60);
        height: 32px;
        line-height: 32px;
        width: 100%;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.87);
        letter-spacing: 0.5px;
        padding: 0 1.6rem;
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
        font-weight: 100;
        position: relative;
      }
      &--content {
        padding-top: 1.8rem;
        padding-bottom: 1.8rem;
        border-bottom: solid 1px rgb(222, 222, 222);

        &-row {
          height: 4rem;
          line-height: 3.2rem;
          padding: 0.2rem 1.5rem;
          font-size: 14px;
          font-weight: normal;
          color: rgb(64, 64, 64);

          input[type='checkbox'] {
            margin-right: 0.9rem;
          }

          input[type='text'] {
            border-radius: 2px;
            border: 1px solid rgba(0, 0, 0, 0.45);
            height: 3.2rem;
            width: 25rem;
            padding: 0.8rem;
            font-size: 14px;
          }
        }
      }

      &-btn {
        float: right;
        position: relative;
        top: 1.6rem;
        right: 1.6rem;
        padding: 0 2.4rem;

        &:hover,
        &.active {
          background-color: $color-grey-disabled;
        }
      }

      &--close {
        width: 40px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;

        &:hover {
          color: rgba(255, 255, 255, 1);
        }

        i {
          top: 0.8rem;
          right: 0.5rem;
          position: absolute;
          font-size: 18px;
        }
      }
    }
  }

  &__item,
  &__box-header {
    @include clearfix;
  }

  &__box,
  &__box-controls,
  &__box-controls-2 {
    position: relative;
  }

  &__box {
    &-header {
      font-size: $font-size-16;
      background: rgb(0, 0, 60);
      height: 40px;
      padding: 1rem 1.6rem;
      color: rgba(255, 255, 255, 0.87);
      letter-spacing: 0.5px;
      position: relative;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      @include respond(tab-port) {
        padding-left: 3rem;
      }
    }
    &-controls {
      @include clearfix;
      &-2 {
        padding-top: 0.6rem;
        &-icon {
          font-size: 1.6rem;
          position: absolute;
          top: 3.8rem;
          right: 0.9rem;
          @include respond(big-desktop) {
            top: 3.2rem;
          }
        }
        @include respond(big-desktop) {
          padding-top: 0.1rem;
        }
      }
    }
    &-content {
      padding: 0 1.6rem;
      height: 100%;
      width: 100%;

      /* overrides */
      .col-1-of-2 {
        padding: 1rem 0 1.6rem 0;
      }
      .input__label,
      .input-row__label {
        margin-top: 0.6rem;
      }
      .input-row__support-msg {
        margin-bottom: 0;
      }
    }
    .app-detail__row {
      background: rgb(255, 255, 255);
      border-radius: 2px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.35);
      margin-top: 3.3rem;
    }
    .app-detail__container {
      height: 80px;
      margin-top: 18px;

      /* override */
      .btn-wrapper {
        text-align: left;
        margin-top: 40px;

        .sr-btn.btn-cancel {
          margin-left: 8px;
        }
      }
    }
  }
  &__menu {
    position: relative;
  }
  &__btn-add {
    position: absolute;
    top: 0;
    right: 0;
    text-transform: capitalize;
    line-height: initial;
  }
  &__btn-remove {
    position: absolute;
    right: 1.6rem;
    text-transform: uppercase;
    top: 50%;
    transform: translateY(-50%);
  }
  &__no-environments {
    font-size: 16px;
    color: #a1a1a1;
    font-weight: 100;
    padding: 20px 0 0 20px;
    display: block;
  }
}

.event-type {
  border: 1px solid #b3b3b3;
  border-radius: 2px;
  background-color: #f3f3f3;
  padding: 0.8rem 1rem;
  margin-bottom: 1.6rem;
  min-height: 4rem;
  @include clearfix;
}
.event-text {
  color: #404040;
  opacity: 0.5;
  font-size: 1.5rem;
  @include clearfix;
}
.event-dialog {
  &__wrapper {
    @include clearfix;
  }
  &__item {
    width: 50%;
    float: left;
    input[type='checkbox'] {
      cursor: pointer;
    }

    input[type='checkbox'] + label,
    input[type='checkbox']:checked + label {
      color: #404040;
      font-style: normal;
    }

    input[type='checkbox'] + label {
      padding-left: 8px;
      cursor: pointer;
    }

    input[type='checkbox']:checked + label {
      color: #ccc;
      font-style: italic;
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
}
