.apps {
  &-wrapper {
    padding-left: 16px;
    flex-grow: inherit;
  }

  &-wrapper,
  &-pagination {
    background-color: $color-grey-light-2;
  }

  &-pagination {
    padding-bottom: 4rem;
  }

  &-header {
    margin-top: 2.4rem;
    display: flex;
  }
}

button {
  &.invite-button {
    width: 100%;
    display: block;
    margin-bottom: 5px;
  }
}

.accordion {
  margin-bottom: 16px;
}
