@import '../abstracts/mixins';

.notification {
  height: 0;
  width: 100%;
  transition: color 100ms, height 200ms linear 100ms;
  color: transparent;
  font-size: 14px;
  list-style: none;
  overflow: hidden;
  top: 40px;
  position: fixed;
  z-index: 1;

  @include respond(phone) {
    top: 48px;
  }

  &-content {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
  }

  &-text {
    padding-left: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-align: center;
    display: block;
  }

  &-close {
    width: 50px;
    text-align: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    color: transparent;
    transition: color 100ms;

    &:hover {
      transition: color 100ms;
      color: transparent;
    }
  }

  &.srm-visible {
    height: 40px;
    color: white;
    box-shadow: -1px -1px 5px #000;
    transition: height 200ms, color 100ms linear 200ms;

    .notification-close {
      color: #daece1;
      transition: color 100ms;

      &:hover {
        color: #fff;
        text-shadow: 0px 0px 3px #fff;
        transition: color 100ms;
      }
    }
  }

  &.srm-warning {
    background-color: #fff19d;
    color: #555555;

    &.srm-visible .notification-close {
      color: #a9a9a9;
      &:hover {
        color: #5a5a5a;
      }
    }
  }

  &.srm-error {
    background-color: #b53045;
  }

  &.srm-success {
    background-color: #159e49;
  }
}
