.u-right-text {
  text-align: right !important;
}

.u-grey-bg {
  background-color: $color-grey-light-2;
}

.u-margin-top-large {
  margin-top: $margin-60 !important;
}

.u-margin-bottom-large {
  margin-bottom: $margin-60 !important;
}

.u-margin-bottom-extra-large {
  margin-bottom: $margin-80 !important;
}

.u-btn-right {
  margin-left: auto;
}

.u-margin-top-80 {
  margin-top: $margin-80 !important;
}

.u-margin-top-42 {
  margin-top: $margin-42 !important;
}

.u-margin-bottom-42 {
  margin-bottom: $margin-42 !important;
}

.u-margin-top-32 {
  margin-top: $margin-32 !important;
}

.u-margin-top-28 {
  margin-top: $margin-28 !important;
}

.u-margin-top-20 {
  margin-top: $margin-20 !important;
}

.u-margin-top-18 {
  margin-top: $margin-18 !important;
}

.u-margin-top-12 {
  margin-top: $margin-12 !important;
}

.u-margin-bottom-12 {
  margin-bottom: $margin-12 !important;
}

.u-margin-top-10 {
  margin-top: $margin-10 !important;
}

.u-margin-top-8 {
  margin-top: $margin-8 !important;
}

.u-margin-bottom-8 {
  margin-bottom: $margin-8 !important;
}

.u-content-margin-top-small {
  margin-top: 1.8rem !important;
}

.u-content-margin-top-extra-small {
  margin-top: 1.6rem !important;
}

.u-text-lowercase {
  text-transform: lowercase !important;
}

.u-text-uppercase {
  text-transform: uppercase !important;
}

.u-pull-right {
  float: right !important;
}

.u-default-height {
  height: $default-box-height !important;
}

.u-font-size-12 {
  font-size: 1.2rem !important;
}

.u-color-orange {
  color: $color-orange !important;
}

.u-color-red {
  color: $color-red-sr !important;
}
.u-color-green {
  color: $alert-color-success !important;
}

.u-color-blue {
  color: $color-blue-link !important;
}

.u-text-2-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.u-text-3-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.u-border-grey {
  border: 1px solid rgba($color-grey-dark, 0.25);
}

.u-label {
  color: #fff !important;
  font-size: 11px !important;
  font-weight: 400 !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  text-transform: uppercase !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
}
.u-bg-green {
  background-color: $alert-color-success !important;
}
.u-bg-red {
  background-color: $color-red-sr !important;
}
.u-bg-orange {
  background-color: $color-orange !important;
}
