.formDialog {
  &--input {
    display: none;
  }
  &--label {
    height: 2.5rem;
    line-height: 2.5rem;
    background-color: $color-grey-light-2;
    color: $color-darkest-grey;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    border: 2px;
    display: block;
    font-size: 1.2rem;
    &:hover {
      background-color: rgba($color-darkest-grey, 0.2);
    }
  }
}
