.icon {
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
  }
  &-header {
    background-color: $color-sr-dark-blue;
    color: $color-white;
    width: 4rem;
    height: 4rem;
    text-align: center;
    display: inline-block;
    font-size: 2.4rem;
    border-radius: 50%;
    margin-right: 1rem;
    line-height: 4rem;
    vertical-align: middle;

    i {
      line-height: 2.5rem;
      vertical-align: text-top;
    }
  }
  &__close {
    color: $color-blue;
    background-color: $color-grey-light-2;
    font-size: 2rem;
    &:hover {
      background-color: rgba($color-darkest-grey, 0.25);
    }
  }
  &__trash {
    font-size: 2rem;
    &:hover {
      color: $color-red-sr;
    }
  }
}

.ic-dashboard:before {
  font-family: 'Sportradar16-3-3', sans-serif;
  content: '\ea6f';
}
.ic-admin:before {
  font-family: 'Sportradar16-3-3', sans-serif;
  content: '\ea0c';
}
.ic-docs:before {
  font-family: 'Sportradar16-3-3', sans-serif;
  content: '\e98f';
}
