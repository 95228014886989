@font-face {
  font-family: 'Sportradar16-3-3';
  src: url('./fonts/Sportradar16-3-3.ttf?iyrchm') format('truetype'),
    url('./fonts/Sportradar16-3-3.woff?iyrchm') format('woff'),
    url('./fonts/Sportradar16-3-3.svg?iyrchm#Sportradar16-3-3') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Sportradar16-3-3', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-16-379-external-website:before {
  content: '\ea79';
}
.icon-16-380-external-document:before {
  content: '\ea7a';
}
.icon-16-381-female-youth:before {
  content: '\ea7b';
}
.icon-16-382-male-youth:before {
  content: '\ea7c';
}
.icon-16-373-circle-full:before {
  content: '\ea73';
}
.icon-16-374-circle-outline:before {
  content: '\ea74';
}
.icon-16-375-close-outline:before {
  content: '\ea75';
}
.icon-16-376-success-outline2:before {
  content: '\ea76';
}
.icon-16-377-close2:before {
  content: '\ea77';
}
.icon-16-378-success2:before {
  content: '\ea78';
}
.icon-16-346-copy:before {
  content: '\ea58';
}
.icon-16-347-prematch:before {
  content: '\ea59';
}
.icon-16-348-tomorrow-date:before {
  content: '\ea5a';
}
.icon-16-349-today-date:before {
  content: '\ea5b';
}
.icon-16-350-yesterday-date:before {
  content: '\ea5c';
}
.icon-16-351-language-regional-settings:before {
  content: '\ea5d';
}
.icon-16-352-language-settings-2:before {
  content: '\ea5e';
}
.icon-16-353-odds:before {
  content: '\ea5f';
}
.icon-16-354-fixtures:before {
  content: '\ea60';
}
.icon-16-355-h2h:before {
  content: '\ea61';
}
.icon-16-356-referee-whistle:before {
  content: '\ea62';
}
.icon-16-357-tables:before {
  content: '\ea63';
}
.icon-16-358-teams:before {
  content: '\ea64';
}
.icon-16-359-venue-stadium:before {
  content: '\ea65';
}
.icon-16-360-overview:before {
  content: '\ea66';
}
.icon-16-361-stop:before {
  content: '\ea67';
}
.icon-16-362-zoom-in-2:before {
  content: '\ea68';
}
.icon-16-363-zoom-out-2:before {
  content: '\ea69';
}
.icon-16-364-volume-control:before {
  content: '\ea6a';
}
.icon-16-365-sja-tooltip:before {
  content: '\ea6b';
}
.icon-16-366-sja-inline:before {
  content: '\ea6c';
}
.icon-16-367-sja-modal:before {
  content: '\ea6d';
}
.icon-16-368-sja-window:before {
  content: '\ea6e';
}
.icon-16-369-widgets:before {
  content: '\ea6f';
}
.icon-16-370-prematch-2:before {
  content: '\ea70';
}
.icon-16-371-match-overview:before {
  content: '\ea71';
}
.icon-16-372-widget-statistics:before {
  content: '\ea72';
}
.icon-16-321-table-sort-outline:before {
  content: '\e9e9';
}
.icon-16-322-table-ascending-outline:before {
  content: '\e9ea';
}
.icon-16-323-table-descending-outline:before {
  content: '\e9eb';
}
.icon-16-324-table-sort:before {
  content: '\e9ec';
}
.icon-16-325-table-ascending:before {
  content: '\ea43';
}
.icon-16-326-table-descending:before {
  content: '\ea44';
}
.icon-16-327-graph:before {
  content: '\ea45';
}
.icon-16-328-graph-document:before {
  content: '\ea46';
}
.icon-16-329-dashboard1:before {
  content: '\ea47';
}
.icon-16-330-admin2:before {
  content: '\ea48';
}
.icon-16-331-back1:before {
  content: '\ea49';
}
.icon-16-332-comment1:before {
  content: '\ea4a';
}
.icon-16-333-add-comment:before {
  content: '\ea4b';
}
.icon-16-334-key1:before {
  content: '\ea4c';
}
.icon-16-335-percent2:before {
  content: '\ea4d';
}
.icon-16-336-dollar2:before {
  content: '\ea4e';
}
.icon-16-337-euro2:before {
  content: '\ea4f';
}
.icon-16-338-pound2:before {
  content: '\ea50';
}
.icon-16-339-yen2:before {
  content: '\ea51';
}
.icon-16-340-social-youtube:before {
  content: '\ea52';
}
.icon-16-341-chart2:before {
  content: '\ea53';
}
.icon-16-342-document3:before {
  content: '\ea54';
}
.icon-16-343-login:before {
  content: '\ea55';
}
.icon-16-344-sitemap2:before {
  content: '\ea56';
}
.icon-16-345-network2:before {
  content: '\ea57';
}
.icon-16-320-solid-attention:before {
  content: '\e9dc';
}
.icon-16-319-solid-question:before {
  content: '\e9dd';
}
.icon-16-318-solid-success:before {
  content: '\e9de';
}
.icon-16-317-solid-information:before {
  content: '\e9df';
}
.icon-16-316-solid-error:before {
  content: '\e9e0';
}
.icon-16-315-sport-pitch-field:before {
  content: '\e9e1';
}
.icon-16-314-scoreboard-match:before {
  content: '\e9e2';
}
.icon-16-313-photo:before {
  content: '\e9e3';
}
.icon-16-312-network:before {
  content: '\e9e4';
}
.icon-16-311-paint-styling:before {
  content: '\e9e5';
}
.icon-16-310-off-switch:before {
  content: '\e9e6';
}
.icon-16-309-question:before {
  content: '\e9e7';
}
.icon-16-308-picture-album:before {
  content: '\e9e8';
}
.icon-16-215-arrow-L-small:before {
  content: '\e9d5';
}
.icon-16-216-arrow-R-small:before {
  content: '\e9d6';
}
.icon-16-217-arrow-U-small:before {
  content: '\e9d7';
}
.icon-16-218-arrow-D-small:before {
  content: '\e9d8';
}
.icon-16-219-more2:before {
  content: '\e9d9';
}
.icon-16-220-hamburger-menu:before {
  content: '\e9da';
}
.icon-16-221-apps:before {
  content: '\e9db';
}
.icon-16-222-globe:before {
  content: '\e9ed';
}
.icon-16-223-globe2:before {
  content: '\e9ee';
}
.icon-16-224-globe3:before {
  content: '\e9ef';
}
.icon-16-225-announcement:before {
  content: '\e9f0';
}
.icon-16-226-fire:before {
  content: '\e9f1';
}
.icon-16-227-history:before {
  content: '\e9f2';
}
.icon-16-228-female-venus:before {
  content: '\e9f3';
}
.icon-16-229-male-mars:before {
  content: '\e9f4';
}
.icon-16-230-coffee:before {
  content: '\e9f5';
}
.icon-16-231-wrench-tool:before {
  content: '\e9f6';
}
.icon-16-232-umbrella:before {
  content: '\e9f7';
}
.icon-16-233-bulb:before {
  content: '\e9f8';
}
.icon-16-234-lab-experiment:before {
  content: '\e9f9';
}
.icon-16-235-eyeglasses:before {
  content: '\e9fa';
}
.icon-16-236-anchor:before {
  content: '\e9fb';
}
.icon-16-237-wallet:before {
  content: '\e9fc';
}
.icon-16-238-pacman:before {
  content: '\e9fd';
}
.icon-16-239-settings2:before {
  content: '\e9fe';
}
.icon-16-240-juice:before {
  content: '\e9ff';
}
.icon-16-241-cursor:before {
  content: '\ea00';
}
.icon-16-242-sitemap:before {
  content: '\ea01';
}
.icon-16-243-newspaper:before {
  content: '\ea02';
}
.icon-16-244-filter:before {
  content: '\ea03';
}
.icon-16-245-sun:before {
  content: '\ea04';
}
.icon-16-246-flare:before {
  content: '\ea05';
}
.icon-16-247-user2-avatar:before {
  content: '\ea06';
}
.icon-16-248-pizzle:before {
  content: '\ea07';
}
.icon-16-249-cube:before {
  content: '\ea08';
}
.icon-16-250-patch:before {
  content: '\ea09';
}
.icon-16-251-compressed-file:before {
  content: '\ea0a';
}
.icon-16-252-image-add:before {
  content: '\ea0b';
}
.icon-16-253-Admin:before {
  content: '\ea0c';
}
.icon-16-254-Admin-settings:before {
  content: '\ea0d';
}
.icon-16-255-social-facebook:before {
  content: '\ea0e';
}
.icon-16-256-social-paypal:before {
  content: '\ea0f';
}
.icon-16-257-social-twitter:before {
  content: '\ea10';
}
.icon-16-258-social-apple:before {
  content: '\ea11';
}
.icon-16-259-social-android:before {
  content: '\ea12';
}
.icon-16-260-social-windows:before {
  content: '\ea13';
}
.icon-16-261-social-instafram:before {
  content: '\ea14';
}
.icon-16-262-social-google-plus:before {
  content: '\ea15';
}
.icon-16-263-social-linkedin:before {
  content: '\ea16';
}
.icon-16-264-social-share:before {
  content: '\ea17';
}
.icon-16-265-social-rss:before {
  content: '\ea18';
}
.icon-16-266-beach-handball:before {
  content: '\ea19';
}
.icon-16-267-note1-music1:before {
  content: '\ea1a';
}
.icon-16-268-note2-music2:before {
  content: '\ea1b';
}
.icon-16-269-note3-music3:before {
  content: '\ea1c';
}
.icon-16-270-dice:before {
  content: '\ea1d';
}
.icon-16-271-spades:before {
  content: '\ea1e';
}
.icon-16-272-diamonds:before {
  content: '\ea1f';
}
.icon-16-273-clubs:before {
  content: '\ea20';
}
.icon-16-274-hearts:before {
  content: '\ea21';
}
.icon-16-275-women-sign:before {
  content: '\ea22';
}
.icon-16-276-men-sign:before {
  content: '\ea23';
}
.icon-16-277-digits:before {
  content: '\ea24';
}
.icon-16-278-telephone:before {
  content: '\ea25';
}
.icon-16-279-devices:before {
  content: '\ea26';
}
.icon-16-280-monitor-desktop:before {
  content: '\ea27';
}
.icon-16-281-heart-health:before {
  content: '\ea28';
}
.icon-16-282-pulse:before {
  content: '\ea29';
}
.icon-16-283-first-aid-case:before {
  content: '\ea2a';
}
.icon-16-284-phone:before {
  content: '\ea2b';
}
.icon-16-285-phone-locked:before {
  content: '\ea2c';
}
.icon-16-286-phone-signal:before {
  content: '\ea2d';
}
.icon-16-287-phone-call-out:before {
  content: '\ea2e';
}
.icon-16-288-phone-call-in:before {
  content: '\ea2f';
}
.icon-16-289-satellite:before {
  content: '\ea30';
}
.icon-16-290-satellite-dish:before {
  content: '\ea31';
}
.icon-16-291-microphone-mute:before {
  content: '\ea32';
}
.icon-16-292-alarm-add:before {
  content: '\ea33';
}
.icon-16-293-time-clock-time:before {
  content: '\ea34';
}
.icon-16-294-clock-time-add:before {
  content: '\ea35';
}
.icon-16-295-clock-time-remove:before {
  content: '\ea36';
}
.icon-16-296-clock-time-check:before {
  content: '\ea37';
}
.icon-16-297-clock-time-cross:before {
  content: '\ea38';
}
.icon-16-298-cloud-lightning:before {
  content: '\ea39';
}
.icon-16-299-cloud-sync:before {
  content: '\ea3a';
}
.icon-16-300-cloud-lock:before {
  content: '\ea3b';
}
.icon-16-301-cloud-gear:before {
  content: '\ea3c';
}
.icon-16-302-cloud-database:before {
  content: '\ea3d';
}
.icon-16-303-cloud-check:before {
  content: '\ea3e';
}
.icon-16-304-cloud-add:before {
  content: '\ea3f';
}
.icon-16-305-cloud-remove:before {
  content: '\ea40';
}
.icon-16-306-cloud-cross:before {
  content: '\ea41';
}
.icon-16-307-cloud-crossed:before {
  content: '\ea42';
}
.icon-16-190-american-football:before {
  content: '\e9bc';
}
.icon-16-191-aussie-rules:before {
  content: '\e9bd';
}
.icon-16-192-badminton:before {
  content: '\e9be';
}
.icon-16-193-bandy:before {
  content: '\e9bf';
}
.icon-16-194-baseball:before {
  content: '\e9c0';
}
.icon-16-195-beach-volleyball:before {
  content: '\e9c1';
}
.icon-16-196-billiard:before {
  content: '\e9c2';
}
.icon-16-197-bowling:before {
  content: '\e9c3';
}
.icon-16-198-cricket:before {
  content: '\e9c4';
}
.icon-16-199-curling:before {
  content: '\e9c5';
}
.icon-16-200-cycling:before {
  content: '\e9c6';
}
.icon-16-201-darts:before {
  content: '\e9c7';
}
.icon-16-202-field-hockey:before {
  content: '\e9c8';
}
.icon-16-203-floorball:before {
  content: '\e9c9';
}
.icon-16-204-futsal:before {
  content: '\e9ca';
}
.icon-16-205-handball:before {
  content: '\e9cb';
}
.icon-16-206-golf:before {
  content: '\e9cc';
}
.icon-16-207-table-tennis:before {
  content: '\e9cd';
}
.icon-16-208-pesapallo:before {
  content: '\e9ce';
}
.icon-16-209-rugby:before {
  content: '\e9cf';
}
.icon-16-210-softball:before {
  content: '\e9d0';
}
.icon-16-211-snooker:before {
  content: '\e9d1';
}
.icon-16-212-waterpolo:before {
  content: '\e9d2';
}
.icon-16-213-volleyball:before {
  content: '\e9d3';
}
.icon-16-214-other:before {
  content: '\e9d4';
}
.icon-16-028-user-female:before {
  content: '\e91b';
}
.icon-16-029-user:before {
  content: '\e91c';
}
.icon-16-030-people:before {
  content: '\e91d';
}
.icon-16-031-user-follow:before {
  content: '\e91e';
}
.icon-16-032-user-following:before {
  content: '\e91f';
}
.icon-16-033-user-unfollow:before {
  content: '\e920';
}
.icon-16-034-plane:before {
  content: '\e921';
}
.icon-16-035-notebook:before {
  content: '\e922';
}
.icon-16-036-teach-learn:before {
  content: '\e923';
}
.icon-16-037-ghost:before {
  content: '\e924';
}
.icon-16-038-energy:before {
  content: '\e925';
}
.icon-16-039-smiley:before {
  content: '\e926';
}
.icon-16-040-credid-card:before {
  content: '\e927';
}
.icon-16-041-speach:before {
  content: '\e928';
}
.icon-16-042-printer:before {
  content: '\e929';
}
.icon-16-043-gift:before {
  content: '\e92a';
}
.icon-16-044-image:before {
  content: '\e92b';
}
.icon-16-045-map:before {
  content: '\e92c';
}
.icon-16-046-briefcase:before {
  content: '\e92d';
}
.icon-16-047-book-open:before {
  content: '\e92e';
}
.icon-16-048-cart:before {
  content: '\e92f';
}
.icon-16-049-cart-full:before {
  content: '\e930';
}
.icon-16-050-shoping-bag:before {
  content: '\e931';
}
.icon-16-051-undo:before {
  content: '\e932';
}
.icon-16-052-redo:before {
  content: '\e933';
}
.icon-16-053-pie-chart:before {
  content: '\e934';
}
.icon-16-054-microphone:before {
  content: '\e935';
}
.icon-16-055-list:before {
  content: '\e936';
}
.icon-16-056-cloud-download:before {
  content: '\e937';
}
.icon-16-057-cloud-upload:before {
  content: '\e938';
}
.icon-16-058-email:before {
  content: '\e939';
}
.icon-16-059-attachment:before {
  content: '\e93a';
}
.icon-16-060-star:before {
  content: '\e93b';
}
.icon-16-061-loudest:before {
  content: '\e93c';
}
.icon-16-062-louder:before {
  content: '\e93d';
}
.icon-16-063-sound-on:before {
  content: '\e93e';
}
.icon-16-064-sound-off:before {
  content: '\e93f';
}
.icon-16-065-database-storage:before {
  content: '\e940';
}
.icon-16-066-database-add:before {
  content: '\e941';
}
.icon-16-067-database-remove:before {
  content: '\e942';
}
.icon-16-068-database-lock:before {
  content: '\e943';
}
.icon-16-069-database-check:before {
  content: '\e944';
}
.icon-16-070-database-upload:before {
  content: '\e945';
}
.icon-16-071-database-download:before {
  content: '\e946';
}
.icon-16-072-shield-security:before {
  content: '\e947';
}
.icon-16-073-shield-check:before {
  content: '\e948';
}
.icon-16-074-shield-alert:before {
  content: '\e949';
}
.icon-16-075-shield-cross:before {
  content: '\e94a';
}
.icon-16-076-lock-privacy:before {
  content: '\e94b';
}
.icon-16-077-unlock:before {
  content: '\e94c';
}
.icon-16-078-flag-report:before {
  content: '\e94d';
}
.icon-16-079-trash:before {
  content: '\e94e';
}
.icon-16-080-paper-plane-send:before {
  content: '\e94f';
}
.icon-16-081-eye-public:before {
  content: '\e950';
}
.icon-16-082-eye-privacy:before {
  content: '\e951';
}
.icon-16-083-save:before {
  content: '\e952';
}
.icon-16-084-document:before {
  content: '\e953';
}
.icon-16-085-document-add:before {
  content: '\e954';
}
.icon-16-086-document-checked:before {
  content: '\e955';
}
.icon-16-087-documents-files-stack:before {
  content: '\e956';
}
.icon-16-088-folder-directory:before {
  content: '\e957';
}
.icon-16-089-folder-search:before {
  content: '\e958';
}
.icon-16-090-folder-add:before {
  content: '\e959';
}
.icon-16-091-folder-remove:before {
  content: '\e95a';
}
.icon-16-092-folder-download:before {
  content: '\e95b';
}
.icon-16-093-folder-upload:before {
  content: '\e95c';
}
.icon-16-094-camera-video:before {
  content: '\e95d';
}
.icon-16-095-camera-no-video:before {
  content: '\e95e';
}
.icon-16-096-camera-play:before {
  content: '\e95f';
}
.icon-16-097-badge-prize:before {
  content: '\e960';
}
.icon-16-098-register-signature:before {
  content: '\e961';
}
.icon-16-099-document-article:before {
  content: '\e962';
}
.icon-16-100-tag:before {
  content: '\e963';
}
.icon-16-101-tags-group:before {
  content: '\e964';
}
.icon-16-102-tshirt-clothes:before {
  content: '\e965';
}
.icon-16-103-mouse-click:before {
  content: '\e966';
}
.icon-16-104-monitor-desktop:before {
  content: '\e967';
}
.icon-16-105-chart-statistics:before {
  content: '\e968';
}
.icon-16-106-bubble-blog-talk:before {
  content: '\e969';
}
.icon-16-107-bubble-conversation:before {
  content: '\e96a';
}
.icon-16-108-bubble-alert:before {
  content: '\e96b';
}
.icon-16-109-bubble-question:before {
  content: '\e96c';
}
.icon-16-110-bubble-text:before {
  content: '\e96d';
}
.icon-16-111-coin-dollar:before {
  content: '\e96e';
}
.icon-16-112-coin-euro:before {
  content: '\e96f';
}
.icon-16-113-laptop:before {
  content: '\e970';
}
.icon-16-114-tablet:before {
  content: '\e971';
}
.icon-16-115-map-market:before {
  content: '\e972';
}
.icon-16-116-diamond:before {
  content: '\e973';
}
.icon-16-117-leaf-nature:before {
  content: '\e974';
}
.icon-16-118-undo2:before {
  content: '\e975';
}
.icon-16-119-redo2:before {
  content: '\e976';
}
.icon-16-120-sync:before {
  content: '\e977';
}
.icon-16-121-download2:before {
  content: '\e978';
}
.icon-16-122-upload2:before {
  content: '\e979';
}
.icon-16-123-enter-up:before {
  content: '\e97a';
}
.icon-16-124-enter-down:before {
  content: '\e97b';
}
.icon-16-125-enter-left:before {
  content: '\e97c';
}
.icon-16-126-enter-right:before {
  content: '\e97d';
}
.icon-16-127-exit-up:before {
  content: '\e97e';
}
.icon-16-128-exit-down:before {
  content: '\e97f';
}
.icon-16-129-exit-left:before {
  content: '\e980';
}
.icon-16-130-exit-right:before {
  content: '\e981';
}
.icon-16-131-plus-square:before {
  content: '\e982';
}
.icon-16-132-minus-square:before {
  content: '\e983';
}
.icon-16-133-percent-square:before {
  content: '\e984';
}
.icon-16-134-arrow-up-square:before {
  content: '\e985';
}
.icon-16-135-arrow-down-square:before {
  content: '\e986';
}
.icon-16-136-arrow-left-square:before {
  content: '\e987';
}
.icon-16-137-arrow-right-square:before {
  content: '\e988';
}
.icon-16-138-chevron-up-square:before {
  content: '\e989';
}
.icon-16-139-chevron-down-square:before {
  content: '\e98a';
}
.icon-16-140-chevron-left-square:before {
  content: '\e98b';
}
.icon-16-141-chevron-right-square:before {
  content: '\e98c';
}
.icon-16-142-check-square:before {
  content: '\e98d';
}
.icon-16-143-cross-square:before {
  content: '\e98e';
}
.icon-16-144-menu-square:before {
  content: '\e98f';
}
.icon-16-145-pointer-up:before {
  content: '\e990';
}
.icon-16-146-pointer-right:before {
  content: '\e991';
}
.icon-16-147-pointer-down:before {
  content: '\e992';
}
.icon-16-148-pointer-left:before {
  content: '\e993';
}
.icon-16-149-layers-stack:before {
  content: '\e994';
}
.icon-16-150-layers-crossed:before {
  content: '\e995';
}
.icon-16-151-check:before {
  content: '\e996';
}
.icon-16-152-plus:before {
  content: '\e997';
}
.icon-16-153-minus:before {
  content: '\e998';
}
.icon-16-154-percent:before {
  content: '\e999';
}
.icon-16-155-arrow-up:before {
  content: '\e99a';
}
.icon-16-156-arrow-down:before {
  content: '\e99b';
}
.icon-16-157-arrow-right:before {
  content: '\e99c';
}
.icon-16-158-arrow-left:before {
  content: '\e99d';
}
.icon-16-159-arrow-right-up:before {
  content: '\e99e';
}
.icon-16-160-arrow-left-up:before {
  content: '\e99f';
}
.icon-16-161-arrow-right-down:before {
  content: '\e9a0';
}
.icon-16-162-arrow-left-down:before {
  content: '\e9a1';
}
.icon-16-163-shuffle:before {
  content: '\e9a2';
}
.icon-16-164-expand-maximize:before {
  content: '\e9a3';
}
.icon-16-165-contract-minimize:before {
  content: '\e9a4';
}
.icon-16-166-expand2:before {
  content: '\e9a5';
}
.icon-16-167-minimize2:before {
  content: '\e9a6';
}
.icon-16-168-move:before {
  content: '\e9a7';
}
.icon-16-169-switch:before {
  content: '\e9a8';
}
.icon-16-170-arrow-wave:before {
  content: '\e9a9';
}
.icon-16-171-thumbs-up-like:before {
  content: '\e9aa';
}
.icon-16-172-thumbs-down-dislike:before {
  content: '\e9ab';
}
.icon-16-173-zoom-in:before {
  content: '\e9ac';
}
.icon-16-174-zoom-out:before {
  content: '\e9ad';
}
.icon-16-175-link:before {
  content: '\e9ae';
}
.icon-16-176-unlink:before {
  content: '\e9af';
}
.icon-16-177-share2:before {
  content: '\e9b0';
}
.icon-16-178-share:before {
  content: '\e9b1';
}
.icon-16-179-bug:before {
  content: '\e9b2';
}
.icon-16-180-code-embed:before {
  content: '\e9b3';
}
.icon-16-181-wifi:before {
  content: '\e9b4';
}
.icon-16-182-wifi-locked:before {
  content: '\e9b5';
}
.icon-16-183-broadcast-live:before {
  content: '\e9b6';
}
.icon-16-184-broadcast-antenna:before {
  content: '\e9b7';
}
.icon-16-185-trophy:before {
  content: '\e9b8';
}
.icon-16-186-speedometer:before {
  content: '\e9b9';
}
.icon-16-187-enter:before {
  content: '\e9ba';
}
.icon-16-188-exit:before {
  content: '\e9bb';
}
.icon-16-001-arrow-d:before {
  content: '\e900';
}
.icon-16-002-arrow-u:before {
  content: '\e901';
}
.icon-16-003-close-x:before {
  content: '\e902';
}
.icon-16-004-dropdown:before {
  content: '\e903';
}
.icon-16-005-search:before {
  content: '\e904';
}
.icon-16-006-edit:before {
  content: '\e905';
}
.icon-16-007-arrow-r:before {
  content: '\e906';
}
.icon-16-008-arrow-l:before {
  content: '\e907';
}
.icon-16-009-time:before {
  content: '\e908';
}
.icon-16-010-calendar:before {
  content: '\e909';
}
.icon-16-011-error:before {
  content: '\e90a';
}
.icon-16-012-info:before {
  content: '\e90b';
}
.icon-16-013-success:before {
  content: '\e90c';
}
.icon-16-014-attention:before {
  content: '\e90d';
}
.icon-16-015-repost:before {
  content: '\e90e';
}
.icon-16-016-more:before {
  content: '\e90f';
}
.icon-16-017-like:before {
  content: '\e910';
}
.icon-16-018-alarm:before {
  content: '\e911';
}
.icon-16-019-pin:before {
  content: '\e912';
}
.icon-16-020-tennis:before {
  content: '\e913';
}
.icon-16-021-soccer:before {
  content: '\e914';
}
.icon-16-022-basketball:before {
  content: '\e915';
}
.icon-16-023-hockey:before {
  content: '\e916';
}
.icon-16-024-home:before {
  content: '\e917';
}
.icon-16-025-rocket:before {
  content: '\e918';
}
.icon-16-026-document:before {
  content: '\e919';
}
.icon-16-027-setings:before {
  content: '\e91a';
}
