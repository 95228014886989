.chart {
  &-box {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.35);
    &__title {
      height: 3.2rem;
      background-color: $color-sr-dark-blue;
      color: $color-white;
      font-size: 1.6rem;
      line-height: 1.9rem;
      letter-spacing: 0.5px;
      padding: 0.7rem 1.6rem;
    }
    &__content {
      padding-right: 1.6rem;
      font-size: 1.4rem;
    }
  }
  &-box:nth-of-type(2) {
    margin-top: 5rem;
  }
}

// rechartjs
.recharts-cartesian-axis-tick {
  font-size: 10px;
}
