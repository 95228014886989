.inquiry {
  h3 {
    font-size: 2.4rem;
  }

  .sr-forms-form-desc {
    color: rgb(64, 64, 64);
    font-size: 16px;
    height: 24px;
    line-height: 24px;
  }

  &-form {
    &.sr-forms-content {
      padding-top: 6.4rem;
    }
  }

  &-button {
    text-align: right;
  }

  .sr-btn {
    margin-top: 1.6rem;
    display: initial;
  }

  &-terms--checkbox {
    display: flex;
  }

  &-terms {
    margin-top: 0.2rem;
    color: rgb(64, 64, 64);

    a:hover {
      text-decoration: underline;
    }
  }

  .activation-message {
    text-align: center;
    color: rgb(64, 64, 64);

    h3 {
      padding: 50px 0 0 0;
    }
    p {
      padding: 30px 0;
    }
  }
}
