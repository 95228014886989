body {
  font-weight: 400;
  color: $color-grey-dark;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $heading-color-secondary;
  }
  // ****** NEW STYLING ******
  h1 {
    font-weight: 400;
    color: $color-sr-dark-blue;
    font-size: 2.8rem;
    line-height: 3.4rem;
  }
  h2 {
    font-size: 2.4rem;
    line-height: 3rem;
  }
  h3 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  h4 {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
  h5 {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  h6 {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
  .body-1 {
    font-size: 2rem;
    line-height: 3.2rem;
  }
  .body-2 {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
  .body-3 {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  .body-4 {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
  .body-5 {
    font-size: 1.3rem;
    line-height: 2rem;
  }

  a {
    &:link,
    &:visited {
      color: $color-blue;
      text-decoration: none;
    }
  }
  button {
    font-family: inherit;
  }
}

// ****** END OF NEW STYLING ******

.heading-primary {
  color: $color-black;
  font-size: $font-size-20;
}
.heading-secondary {
  color: $color-black;
  font-size: 1.6rem;
}
.heading-tertiary {
  font-size: $font-size-14;
  font-weight: 700;
  margin-bottom: 0.8rem;
}
.paragraph {
  font-size: 1.6rem;
  color: #404040;
  font-weight: 400;
  &--secondary {
    font-size: $font-size-14;
  }
}
.error-text {
  color: $color-red-sr;
}
.span__plan {
  background-color: #138c5a;
  color: #fff;
  border-radius: 2px;
  width: 56px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  text-align: center;
  margin-bottom: 0.8rem;
}

.dialog__heading {
  &-secondary {
    color: rgba($color-grey-dark, 0.8);
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    padding: 2rem 0;
  }
}
.hint-text {
  font-style: italic;
  text-align: left;
}
