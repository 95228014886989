.mdp-input {
  height: $default-box-height;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin-bottom: 0.8rem;
  font-size: inherit;
  -moz-border: 1px solid #d4d4d4;
  -webkit-border-before: 1px solid #d4d4d4;
  border: 1px solid #d4d4d4;
  &:focus {
    background-color: #fff;
    border: 1px solid $color-blue;
    outline: none;
  }
  &-disabled {
    background-color: #f3f3f3;
    border: none;
  }
  &-error {
    border: 1px solid $color-red-dark;
    background-color: $color-red-light;
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba($color-grey-dark, 0.5);
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba($color-grey-dark, 0.5);
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba($color-grey-dark, 0.5);
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: rgba($color-grey-dark, 0.5);
  }
}

.register-input {
  height: $default-box-height;
  padding-left: 17px;
  margin-bottom: 0.8rem;
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #d4d4d4;
  font-size: $font-size-14;
  &:focus {
    background-color: #fff;
    border: 1px solid $color-blue;
  }
}

.input__wrapper {
  @include clearfix;
}
// new styling
.input-row {
  &__wrapper {
    @include clearfix;
  }
  &__input-contanier {
    position: relative;
    .input-icon {
      font-size: 1.6rem;
      position: absolute;
      top: 1px;
      right: 0;
      height: 3.9rem;
      display: inline-block;
      padding: 0.8rem;
    }
    .remove-icon {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      height: 4rem;
      width: 4rem;
      background-color: $color-red-sr;
      color: $color-white;
      line-height: 4rem;
      text-align: center;
      padding-top: 0.1rem;
      border-radius: 2px;
      font-size: 2rem;
      cursor: pointer;
      &:hover {
        background-color: $color-red-darkest;
      }
      &.disabled {
        background-color: #f3f3f3;
        color: rgba($color-grey-dark, 0.5);
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
  &__label {
    font-size: 1.3rem;
    color: #3f3f41;
    display: block;
    margin-top: 0.8rem;
    margin-bottom: 0.6rem;
    @include clearfix;
  }
  &__input {
    outline: none;
    border: 1px solid #b3b3b3;
    height: 4rem;
    padding: 0.8rem 3.5rem 0.8rem 0.8rem;
    width: 100%;
    font-size: 1.4rem;
    border-radius: 2px;
    &:hover {
      cursor: pointer;
      border: 1px solid #7b7b7b;
    }
    &:focus {
      border: 1px solid $color-blue-link;
      background: #fff;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: $color-grey-light-2;
      border: none;
      color: rgba($color-grey-dark, 0.5);
      &:hover {
        background-color: $color-grey-light-2;
      }
      &:active {
        border: none;
      }
    }
    &:active {
      background-color: #fff;
      border: 1px solid $color-blue-link;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #404040;
      opacity: 0.5;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #404040;
      opacity: 0.5;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #404040;
      opacity: 0.5;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #404040;
      opacity: 0.5;
    }
    &.remove {
      width: 93.5%;
    }
  }

  &__select {
    color: #535355;
  }
  &__support-msg {
    display: block;
    text-align: left;
    font-size: 1.2rem;
    margin-top: 0.8rem;
    margin-bottom: 1.6rem;
  }
  &__icon {
    height: 2.4rem;
    width: 2.4rem;
    text-align: center;
  }
}

// date picker styling
.input--date {
  background: #fff url(../../images/icon-calendar.png) 97% 50% no-repeat;
  outline: none;
  border: 1px solid #b3b3b3;
  height: 4rem;
  width: 100%;
  font-size: 1.5rem;
  border-radius: 2px;
  &:focus {
    background-color: #fff;
    border: 1px solid $color-blue;
    outline: none;
    -webkit-appearance: none;
  }
  &::-webkit-datetime-edit {
    padding: 0.8rem 1rem;
    color: #999999;
  }
  &::-webkit-datetime-edit-text {
    color: #999999;
    padding: 0 0.3em;
  }
  &::-webkit-inner-spin-button {
    opacity: 0;
    z-index: -1;
  }
  &::-webkit-clear-button {
    &:hover {
      cursor: pointer;
    }
  }
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 0;
  }
}
// TEXT Input
.input {
  outline: none;
  border: 1px solid #b3b3b3;
  height: 4rem;
  padding: 0.8rem 3.5rem 0.8rem 0.8rem;
  width: 100%;
  font-size: 1.4rem;
  border-radius: 2px;
  &:hover {
    cursor: pointer;
    border: 1px solid #7b7b7b;
  }
  &:focus {
    border: 1px solid $color-blue-link;
    background: #fff;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: $color-grey-light-2;
    border: none;
    color: rgba($color-grey-dark, 0.5);
    &:hover {
      background-color: $color-grey-light-2;
    }
    &:active {
      border: none;
    }
  }
  &:disabled + &__icon {
    opacity: 0.5;
  }
  &:active {
    background-color: #fff;
    border: 1px solid $color-blue-link;
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #404040;
    opacity: 0.5;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #404040;
    opacity: 0.5;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #404040;
    opacity: 0.5;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: #404040;
    opacity: 0.5;
  }
  &__container {
    position: relative;
  }
  &__label {
    font-size: 1.3rem;
    margin-top: 0.8rem;
    margin-bottom: 0.6rem;
    display: block;
    @include clearfix;
  }
  &__icon {
    display: inline-block;
    width: 2.4rem;
    height: 4rem;
    font-size: 2rem;
    position: absolute;
    right: 6px;
    padding-top: 0.8rem;
  }
}

button.upload-file {
  padding: 4px;
  width: max-content;
  margin-bottom: 4px;
}

.date-group {
  display: flex;
  column-gap: 16px;
  align-items: center;

  input.date_input {
    width: calc(100% - 40px);
  }
}
